import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as SpreadIcon } from '../../../../assets/icons/spread.svg';
import { Button, LoadingButton } from '../../../../components/buttons';
import { GoBack } from '../../../../components/go-back';
import Select from '../../../../components/inputs/new-select';
import FormikTextInput from '../../../../components/inputs/text-input-with-formik';
import { Loader } from '../../../../components/loader';
import { Text } from '../../../../containers/MesssageContainers';
import { FlexRowEnd, FlexCentredRow } from '../../../../containers/ScreenContainers';
import { getScoreDistribution, setScoreDistribution } from '../../../../redux/result/actions';

import { ValidationSchema } from './ValidationSchema';

const BroadsheetSetScoreDistribution = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading } = useSelector((store) => store.result);
    const { state } = useLocation();
    const { course, session } = state || {};
    const { courseCode, courseTitle, _id: courseId } = course || {};

    const [distribution, setDistribution] = useState(null);

    const [altLoading, setAltLoading] = useState(false);

    useEffect(() => {
        if (!session || !courseId) return;

        const getScores = async () => {
            const data = await dispatch(getScoreDistribution(session, courseId));

            if (data) {
                setDistribution(data);
                return;
            }
            setDistribution(null);
        };

        getScores();
    }, [courseId, dispatch, session]);

    if (isLoading) return <Loader />;

    return (
        <>
            <GoBack title="Settings / Score Distribution" subTitle={`${courseCode} (${courseTitle})`} />

            <div className="bg-white rounded-2xl px-[32px] py-[36px] max-w-[1020px] mt-[4rem]">
                <FlexCentredRow className="mb-4">
                    <SpreadIcon />
                    <Text left="1.4rem" size="1.6rem" lineHeight="2.4rem" weight="700">
                        Set Score Distribution
                    </Text>
                </FlexCentredRow>
                <Formik
                    initialValues={{
                        attendanceScore: distribution?.attendanceScore || '',
                        assignmentScore: distribution?.assignmentScore || '',
                        testScore: distribution?.testScore || '',
                        examScore: distribution?.examScore || '',
                        quizScore: distribution?.quizScore || '',
                    }}
                    validationSchema={ValidationSchema}
                    enableReinitialize
                    onSubmit={async (values) => {
                        const { attendanceScore, assignmentScore, testScore, examScore, quizScore } = values;

                        const totalPercentage =
                            Number(attendanceScore) +
                            Number(assignmentScore) +
                            Number(testScore) +
                            Number(examScore) +
                            Number(quizScore);

                        if (totalPercentage < 100) return toast.error("Total score percentage can't be less than 100!");
                        if (totalPercentage > 100)
                            return toast.error("Total score percentage can't be greater than 100!");

                        setAltLoading(true);

                        const res = await dispatch(setScoreDistribution(session, courseId, values));

                        if (res) {
                            toast.success('Score distribution saved successfully!');
                        }
                        setAltLoading(false);
                    }}
                >
                    {({ errors, handleChange, values }) => {
                        return (
                            <Form>
                                <div className="grid gap-8 max-w-[396px]">
                                    <Select
                                        label="Session"
                                        name="session"
                                        objProp="session"
                                        error={errors.session}
                                        passedSelectedItems={{ session }}
                                        disabled
                                        placeholder="Session"
                                        // data={sessions}
                                        // onChange={(selected) => {
                                        //     setFieldValue('session', selected[0].admissionYear);
                                        // }}
                                    />

                                    <FormikTextInput
                                        label="Attendance Score (%)"
                                        name="attendanceScore"
                                        value={values.attendanceScore}
                                        errors={errors}
                                        type="number"
                                        placeholder="Attendance Score (%)"
                                        onChange={handleChange}
                                    />
                                    <FormikTextInput
                                        label="Quiz Score (%)"
                                        name="quizScore"
                                        value={values.quizScore}
                                        errors={errors}
                                        type="number"
                                        placeholder="Quiz Score (%)"
                                        onChange={handleChange}
                                    />
                                    <FormikTextInput
                                        label="Assignment Score (%)"
                                        name="assignmentScore"
                                        value={values.assignmentScore}
                                        errors={errors}
                                        type="number"
                                        placeholder="Assignment Score (%)"
                                        onChange={handleChange}
                                    />

                                    {/* <FormikTextInput
                                        label="Assignment Score Used  as Test(%)"
                                        name="assignmentScoreUsedAsTest"
                                        value={values.assignmentScoreUsedAsTest}
                                        errors={errors}
                                        type="number"
                                        placeholder="Assignment Score Used as Test (%)"
                                        onChange={handleChange}
                                    /> */}
                                    <FormikTextInput
                                        label="Test Score (%)"
                                        name="testScore"
                                        value={values.testScore}
                                        errors={errors}
                                        type="number"
                                        placeholder="Test Score (%)"
                                        onChange={handleChange}
                                    />
                                    <FormikTextInput
                                        label="Exam Score (%)"
                                        name="examScore"
                                        value={values.examScore}
                                        errors={errors}
                                        type="number"
                                        placeholder="Exam Score (%)"
                                        onChange={handleChange}
                                    />
                                    <FormikTextInput
                                        label="Total (Percentage of Overall Score)"
                                        name="total"
                                        min={100}
                                        max={100}
                                        value={
                                            Number(values.attendanceScore) +
                                            Number(values.assignmentScore) +
                                            Number(values.testScore) +
                                            Number(values.examScore) +
                                            Number(values.quizScore)
                                        }
                                        disabled
                                        errors={errors}
                                        type="number"
                                        placeholder="Total (Percentage of Overall Score)"
                                        onChange={handleChange}
                                    />
                                </div>
                                <FlexRowEnd className="gap-x-[16px] mt-8">
                                    <Button onClick={() => navigate(-1)} type="button" border="0.5px solid #D1D5DB">
                                        Cancel
                                    </Button>

                                    <LoadingButton disabled={altLoading} type="submit" loading={altLoading}>
                                        Save Changes
                                    </LoadingButton>
                                </FlexRowEnd>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

export default BroadsheetSetScoreDistribution;
