import React from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as EditIcon } from '../../assets/icons/pen.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow } from '../../containers/ScreenContainers';
import { capitalizeFirstLetter } from '../../utils';

const AccountInformation = () => {
    const { userData } = useSelector((state) => state.user);

    return (
        <div className="flex flex-col items-center justify-center gap-[16px]">
            <div className="relative">
                <div className="flex items-center justify-center w-[5.9rem] h-[5.9rem] rounded-full bg-[#4338CA]">
                    <Text size="3.2rem" align="center" weight="400" color="#fff">
                        {capitalizeFirstLetter(userData?.surname)?.charAt(0)}
                    </Text>
                </div>
                <div className="absolute top-[41px] left-[40px] flex items-center justify-center w-[2.1rem] h-[2.1rem] bg-[#D9D9D9] rounded-full">
                    <EditIcon fill="#1F2937" />
                </div>
            </div>
            <div className="flex flex-col gap-[4px] items-center justify-center">
                <Text align="center" size="1.6rem" weight="400" color="#1F2937" lineHeight="2.4rem">
                    {capitalizeFirstLetter(userData?.firstName)} {capitalizeFirstLetter(userData?.surname)}
                </Text>
                {userData?.staffNumber && (
                    <FlexCentredRow className="gap-[8px]">
                        <Text align="center" size="1.6rem" weight="400" color="#6B7280" lineHeight="2.4rem">
                            Staff ID:
                        </Text>
                        <Text align="center" size="1.6rem" weight="600" color="#6B7280" lineHeight="2.4rem">
                            {userData?.staffNumber}
                        </Text>
                    </FlexCentredRow>
                )}
                <Text align="center" size="1.2rem" weight="400" color="#6B7280" lineHeight="1.7rem">
                    {userData?.organizationName}
                </Text>
            </div>
        </div>
    );
};

export default AccountInformation;
