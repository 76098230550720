import { ReactComponent as LoaderIcon } from '../../assets/icons/loader.svg';
import { Text } from '../../containers/MesssageContainers';
import { CenteredContainer } from '../../containers/ScreenContainers';

export const Loader = ({ message }) => {
    return (
        <CenteredContainer className="flex-column h-screen">
            <LoaderIcon className="w-[8rem] h-[8rem] animate-spin" />
            <Text color="#312E81" weight="600" size="2.3rem">
                {message || 'Getting Everything Ready'}
            </Text>
        </CenteredContainer>
    );
};
