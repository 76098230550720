import React, { useState } from 'react';

import { LoadingButton } from '../../../components/buttons';
import Checkbox from '../../../components/inputs/checkbox';
import RadioInput from '../../../components/inputs/radio';

import FillQuestion from './FillQuestion';
import { QUESTION_TYPE_VALUES } from './utils';

const Question = ({
    errors,
    setFieldValue,
    handleChange,
    question,
    parentName,
    index,
    handleDelete,
    showDelete,
    isLastItem,
    handleAdd,
    assignmentQuestionType,
}) => {
    const InputType = question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ? RadioInput : Checkbox;

    const [isSelectAnswer, setIsSelectAnswer] = useState(false);

    const handleCheckbox = (value) => {
        const field = `${parentName}.correctAnswer`;
        const selectedItems = new Set(question.correctAnswer);

        if (selectedItems.has(value)) {
            selectedItems.delete(value);
        } else {
            selectedItems.add(value);
        }

        setFieldValue(field, Array.from(selectedItems.values()));
    };

    return (
        <>
            {!isSelectAnswer ? (
                <FillQuestion
                    index={index}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                    showDelete={showDelete}
                    isLastItem={isLastItem}
                    handleAdd={handleAdd}
                    assignmentQuestionType={assignmentQuestionType}
                    parentName={parentName}
                    InputType={InputType}
                    errors={errors}
                    question={question}
                    setFieldValue={setFieldValue}
                    setIsSelectAnswer={setIsSelectAnswer}
                />
            ) : (
                <div className="grid gap-[24px] bg-white p-[3.5rem] rounded-2xl">
                    {question.options.map((option) => (
                        <div key={option}>
                            {question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ? (
                                <RadioInput
                                    label={option}
                                    checked={question.correctAnswer.includes(option)}
                                    onClick={() => setFieldValue(`${parentName}.correctAnswer`, [option])}
                                />
                            ) : (
                                <Checkbox
                                    key={option}
                                    onClick={() => handleCheckbox(option)}
                                    checked={question.correctAnswer.includes(option)}
                                    label={option}
                                />
                            )}
                        </div>
                    ))}

                    <div className="flex justify-end">
                        <LoadingButton onClick={() => setIsSelectAnswer(false)}>Done</LoadingButton>
                    </div>
                </div>
            )}
        </>
    );
};

export default Question;
