import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_CAMPUSES, LOADING, STOP_LOADING } from './slice';

export const getAllCampus = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff/campus`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_CAMPUSES(data));
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
