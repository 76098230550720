import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';
import { Loader } from '../../../components/loader';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { getStudentAssignment } from '../../../redux/assignment/actions';

import GradedList from './GradedList';
import ReadyToGradeList from './ReadyToGradeList';

const ViewSubmission = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { isLoading } = useSelector((store) => store.assignment);

    const { assignment, student } = state;
    const assignmentId = assignment._id;
    const studentId = student._id;

    const [submissionDetails, setSubmissionDetails] = useState(null);

    const questions = submissionDetails?.assignment?.questions;

    const totalScore = useMemo(
        () => questions?.reduce((total, question) => total + question.studentScore || 0, 0),
        [questions],
    );

    useEffect(() => {
        const getDetails = async () => {
            const data = await dispatch(getStudentAssignment(assignmentId, studentId));
            if (data) {
                setSubmissionDetails(data);
            }
        };

        getDetails();
    }, [assignmentId, dispatch, studentId]);

    if (isLoading) return <Loader />;

    return (
        <section className="pb-20 max-w-[1000px]">
            <div className="mb-10">
                <GoBack
                    title={`Assignments / ${assignment?.courseCode} / Submissions`}
                    subTitle={`${student.lastName} ${student.middleName || ''} ${student.firstName}`}
                />
            </div>
            <div className="flex justify-between items-center">
                <PageTitle align="left">{assignment.title}</PageTitle>

                <div className="flex gap-4">
                    <Text size="1.6rem" color="#4B5563" align="left">
                        Total Mark
                    </Text>

                    <div className="rounded px-4" style={{ border: '1px solid #6366F1' }}>
                        <Text weight="600" size="1.9rem" color="#6366F1">
                            {totalScore}/{submissionDetails?.assignment?.totalMarks}
                        </Text>
                    </div>
                </div>
            </div>

            <Text size="1.6rem" color="#6B7280" align="left">
                {assignment.description}
            </Text>

            {student?.graded ? (
                <GradedList questions={questions} />
            ) : (
                <ReadyToGradeList assignmentId={assignmentId} studentId={studentId} questions={questions} />
            )}
        </section>
    );
};

export default ViewSubmission;
