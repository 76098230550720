import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getExams = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}exam/:campusId/get-all-exams/${session}/${courseId}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createExam = (courseId, session, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}exam/:campusId/create-exam/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Exam created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editExam = (examId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}exam/:campusId/edit-exam/${examId}`, payload);
        if (response.status === 200) {
            toast.success('Exam updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteExam = (examId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}exam/:campusId/delete-exam/${examId}`);
        if (response.status === 200) {
            toast.success('Exam deleted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamsSpreadsheet = (session, courseId, examId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/exam-spreadsheet/${session}/${courseId}/${examId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamScoresheet = (session, courseId, version) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/exam-scoresheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const markStudentExam = (params) => async (dispatch, getState) => {
    const { courseId, session, studentId, examId, version, payload } = params;

    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}exam/:campusId/mark-exam/${session}/${courseId}/${studentId}/${examId}/${version}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Exam marked successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createNewExamVersion = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}exam/:campusId/create-new-exam-version/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('New version created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const promoteExamVersion = (courseId, session, latestVersion, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}exam/:campusId/promote-exam-scoresheet-version/${courseId}/${session}/${latestVersion}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Version promoted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendExamToBroadsheet = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}exam/:campusId/send-exam-scoresheet-to-broadsheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Scoresheet was sent to CA successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamVersions = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}exam/:campusId/exam-scoresheet-versions-history/${courseId}/${session}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};