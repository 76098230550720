import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { LOADING, STOP_LOADING } from './slice';

export const getTests = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}test/:campusId/get-all-test/${session}/${courseId}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getTestsSpreadsheet = (session, courseId, testId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}test/:campusId/test-answer-spreadsheet/${session}/${courseId}/${testId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getTestsScoresheet = (version, session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}test/:campusId/test-scoresheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getTestVersions = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}test/:campusId/scoresheet-versions/${courseId}/${session}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createTest = (courseId, session, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}test/:campusId/create-test/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Test created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createNewTestVersion = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}test/:campusId/create-new-test-version/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('New version created successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const promoteTestVersion = (courseId, session, latestVersion, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}test/:campusId/promote-test-scoresheet-version/${courseId}/${session}/${latestVersion}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Version promoted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendTestToBroadsheet = (courseId, session, version) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}test/:campusId/send-scoresheet-to-broadsheet/${courseId}/${session}/${version}`,
        );
        if (response.status === 200) {
            toast.success('Scoresheet was sent to CA successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editTest = (testId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(`${currentAPI}test/:campusId/edit-test/${testId}`, payload);
        if (response.status === 200) {
            toast.success('Test updated successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteTest = (testId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.delete(`${currentAPI}test/:campusId/delete-test/${testId}`);
        if (response.status === 200) {
            toast.success('Test deleted successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const markStudentTest = (params) => async (dispatch, getState) => {
    const { courseId, session, studentId, testId, payload, version } = params;

    dispatch(LOADING());
    try {
        const response = await axiosInstance.put(
            `${currentAPI}test/:campusId/mark-test/${session}/${courseId}/${studentId}/${testId}/${version}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Test marked successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
