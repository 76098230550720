// Pages that can only be visited when user is authenticated
import {
    AllotedCoursesList,
    AllotedCoursesDetails,
    Dashboard,
    StudentDetails,
    Students,
    Personnel,
    Received,
    Sent,
    Draft,
    LecturerDetails,
    Results,
    Schedule,
    PlatformAssignmentDetails,
    LearningResources,
    CourseResources,
    AssignmentSpreadsheet,
    ViewSubmission,
    EditAssignment,
    CreateAssignment,
    StudentDetail,
    MailPreview,
    NoticePreview,
    InternalMemoPreview,
    AssignmentScoresheetDetails,
    CreateTest,
    EditTest,
    CourseTests,
    TestScoresheetDetails,
    CourseExams,
    CreateExam,
    EditExam,
    ExamsScoresheetDetails,
    TestSpreadsheet,
    ExamSpreadsheet,
    CourseAttendance,
    CreateAttendance,
    EditAttendance,
    AttendanceScoresheetDetails,
    AttendanceSpreadsheet,
    CaBroadsheet,
    ExamBroadsheet,
    BroadsheetSettings,
    BroadsheetSetScoreDistribution,
} from '../views';
import CourseAssignments from '../views/assignment/course-assignments';
import ViewDetails from '../views/biodata-details';
import Mail from '../views/communications/compose/mail';
import Notice from '../views/communications/compose/notice';
import Handbook from '../views/handbook';
import HandbookDetails from '../views/handbook/handbookDetails';
import StaffProfile from '../views/staffProfile';
export const protectedRoutes = [
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/dashboard/bio-data',
        element: <StaffProfile />,
    },
    {
        path: '/dashboard/view-details',
        element: <ViewDetails />,
    },
    {
        path: '/allotted-courses',
        element: <AllotedCoursesList />,
    },
    {
        path: '/allotted-courses/:id',
        element: <AllotedCoursesDetails />,
    },
    {
        path: '/allotted-courses/student/:id',
        element: <StudentDetails />,
    },
    {
        path: '/communications/received',
        element: <Received />,
    },
    {
        path: '/communications/received/mail',
        element: <Mail pageTitle="Mail" />,
    },
    {
        path: '/communications/received/letter',
        element: <Mail pageTitle="Letter" />,
    },
    {
        path: '/communications/received/notice',
        element: <Notice pageTitle="Notice" />,
    },
    {
        path: '/communications/mail/:id',
        element: <MailPreview />,
    },
    {
        path: '/communications/notice/:id',
        element: <NoticePreview />,
    },
    {
        path: '/communications/internal-memo/:id',
        element: <InternalMemoPreview />,
    },
    {
        path: '/communications/sent/mail/:id',
        element: <MailPreview />,
    },
    {
        path: '/communications/sent/notice/:id',
        element: <NoticePreview />,
    },
    {
        path: '/communications/sent/mail',
        element: <Mail pageTitle="Mail" />,
    },
    {
        path: '/communications/sent/letter',
        element: <Mail pageTitle="Letter" />,
    },
    {
        path: '/communications/sent/notice',
        element: <Notice pageTitle="Notice" />,
    },
    {
        path: '/communications/draft/mail',
        element: <Mail pageTitle="Mail" />,
    },
    {
        path: '/communications/draft/letter',
        element: <Mail pageTitle="Letter" />,
    },
    {
        path: '/communications/draft/notice',
        element: <Mail pageTitle="Notice" />,
    },
    {
        path: '/communications/sent',
        element: <Sent />,
    },
    {
        path: '/communications/draft',
        element: <Draft />,
    },
    {
        path: '/student',
        element: <Students />,
    },
    {
        path: '/student/:id',
        element: <StudentDetail />,
    },
    {
        path: '/personnel',
        element: <Personnel />,
    },
    {
        path: '/personnel/lecturers/:id',
        element: <LecturerDetails />,
    },
    {
        path: '/results',
        element: <Results />,
    },
    {
        path: '/schedules',
        element: <Schedule />,
    },
    {
        path: '/resources',
        element: <LearningResources />,
    },
    {
        path: '/resources/:id',
        element: <CourseResources />,
    },
    {
        path: '/resources/:id/:folderId',
        element: <CourseResources />,
    },
    {
        path: '/handbook',
        element: <Handbook />,
    },
    {
        path: '/handbook/:id',
        element: <HandbookDetails />,
    },
    {
        path: '/assignments',
        element: <AllotedCoursesList pageTitle="Assignments" />,
    },
    {
        path: '/assignments/:id',
        element: <CourseAssignments />,
    },
    {
        path: '/assignments/create/:id',
        element: <CreateAssignment />,
    },
    {
        path: '/assignments/details/:id',
        element: <PlatformAssignmentDetails />,
    },
    {
        path: '/assignments/details/submissions/:id',
        element: <ViewSubmission />,
    },
    {
        path: '/assignments/update/:id',
        element: <EditAssignment />,
    },
    {
        path: '/assignments/spreadsheet/:id',
        element: <AssignmentSpreadsheet />,
    },
    {
        path: '/assignment-scoresheet',
        element: <AllotedCoursesList pageTitle="Scoresheet" />,
    },
    {
        path: '/assignment-scoresheet/:id',
        element: <AssignmentScoresheetDetails />,
    },
    {
        path: '/tests',
        element: <AllotedCoursesList pageTitle="Tests" />,
    },
    {
        path: '/tests/:id',
        element: <CourseTests />,
    },
    {
        path: '/tests/create/:id',
        element: <CreateTest />,
    },
    {
        path: '/tests/update/:id',
        element: <EditTest />,
    },
    {
        path: '/tests/test-spreadsheet/:id',
        element: <TestSpreadsheet />,
    },
    {
        path: '/test-scoresheet',
        element: <AllotedCoursesList pageTitle="Test Scoresheet" />,
    },
    {
        path: '/test-scoresheet/:id',
        element: <TestScoresheetDetails />,
    },
    {
        path: '/exams',
        element: <AllotedCoursesList pageTitle="Exams" />,
    },
    {
        path: '/exams/:id',
        element: <CourseExams />,
    },
    {
        path: '/exams/create/:id',
        element: <CreateExam />,
    },
    {
        path: '/exams/update/:id',
        element: <EditExam />,
    },
    {
        path: '/exams/exam-spreadsheet/:id',
        element: <ExamSpreadsheet />,
    },
    {
        path: '/exam-scoresheet',
        element: <AllotedCoursesList pageTitle="Exam Scoresheet" />,
    },
    {
        path: '/exam-scoresheet/:id',
        element: <ExamsScoresheetDetails />,
    },
    {
        path: '/attendance',
        element: <AllotedCoursesList pageTitle="Attendance" />,
    },
    {
        path: '/attendance/:id',
        element: <CourseAttendance />,
    },
    {
        path: '/attendance/create/:id',
        element: <CreateAttendance />,
    },
    {
        path: '/attendance/update/:id',
        element: <EditAttendance />,
    },
    {
        path: '/attendance/attendance-spreadsheet/:id',
        element: <AttendanceSpreadsheet />,
    },
    {
        path: '/scoresheet-attendance',
        element: <AllotedCoursesList pageTitle="Attendance Scoresheet" />,
    },
    {
        path: '/scoresheet-attendance/:id',
        element: <AttendanceScoresheetDetails />,
    },
    {
        path: '/ca-broadsheet',
        element: <AllotedCoursesList pageTitle="CA Broadsheet" />,
    },
    {
        path: '/ca-broadsheet/:id',
        element: <CaBroadsheet />,
    },
    {
        path: '/exam-broadsheet',
        element: <AllotedCoursesList pageTitle="Exam Broadsheet" />,
    },
    {
        path: '/exam-broadsheet/:id',
        element: <ExamBroadsheet />,
    },
    {
        path: '/broadsheet-settings',
        element: <BroadsheetSettings />,
    },
    {
        path: '/broadsheet-settings/score-distribution',
        element: <AllotedCoursesList pageTitle="Courses" />,
    },
    {
        path: '/broadsheet-settings/score-distribution/:id',
        element: <BroadsheetSetScoreDistribution />,
    },
];
