import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';

import Layout from './layout';
// import { logoutUser } from './redux/user/actions';
import { routes } from './routes';
import { parseJSON } from './utils/useJSON';

import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const dispatch = useDispatch();
    const token = parseJSON('token', '');

    const isAuthenticated = token ? true : false;

    useEffect(() => {
        if (!token) {
            // dispatch(logoutUser(navigate));
            // navigate('/sign-in-returning');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, token]);

    return (
        <Routes>
            {routes.map((route) => (
                <Route
                    key={route.path}
                    path={isAuthenticated ? '/' : route.path}
                    element={isAuthenticated ? <Navigate to="/dashboard" replace /> : route.element}
                />
            ))}

            <Route path="*" element={<Layout />} />
        </Routes>
    );
}

export default App;
