import React, { useRef } from 'react';

import { FieldArray } from 'formik';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/mark-sm.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus-blue.svg';
// import { Button } from '../../../components/buttons';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input';

import { QUESTION_TYPE_VALUES, QUESTION_TYPE_LOOKUP } from './utils';

const FillQuestion = ({
    index,
    handleChange,
    handleDelete,
    showDelete,
    isLastItem,
    handleAdd,
    assignmentQuestionType,
    parentName,
    InputType,
    errors,
    question,
    setFieldValue,
    setIsSelectAnswer,
}) => {
    const fileInputRef = useRef(null);

    // eslint-disable-next-line no-unused-vars
    const handleOpenFileInuput = () => {
        fileInputRef.current.click();
    };

    const assignmentQuestionTypeOptions = QUESTION_TYPE_LOOKUP[assignmentQuestionType];
    const selectedAssignmentQuestionType = assignmentQuestionTypeOptions?.find(
        (item) => item.value === question.questionType,
    );

    return (
        <div className="grid gap-[24px] bg-white p-[3.5rem] rounded-2xl relative">
            {isLastItem && (
                <div
                    onClick={handleAdd}
                    className="absolute -right-[60px] top-[50%] cursor-pointer p-4 bg-white rounded-[4px]"
                >
                    <PlusIcon />
                </div>
            )}
            <div className="grid grid-cols-2 gap-[24px] text-[#1F2937]">
                <Select
                    name={`${parentName}.questionType`}
                    objProp="name"
                    label="Type"
                    error={errors?.questionType}
                    placeholder="Type"
                    data={assignmentQuestionTypeOptions}
                    useComponentState={false}
                    passedSelectedItems={
                        question.questionType ? [{ name: selectedAssignmentQuestionType?.name }] : null
                    }
                    onChange={(selected) => {
                        setFieldValue(`${parentName}.questionType`, selected[0].value);
                    }}
                />

                <TextInput
                    label="Marks / Points"
                    name={`${parentName}.maxMarks`}
                    type="number"
                    placeholder="Marks/Points"
                    errors={errors}
                    onChange={handleChange}
                    value={question.maxMarks}
                />
            </div>
            <TextAreaInput
                name={`${parentName}.questionText`}
                label={`Question ${index + 1}`}
                value={question.questionText}
                errorMessage={errors?.questionText}
                placeholder="Question"
                height="8rem"
                onChange={handleChange}
            />

            {(question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ||
                question.questionType === QUESTION_TYPE_VALUES.MultipleAnswer) && (
                <FieldArray
                    name={`${parentName}.options`}
                    render={({ insert, remove, push }) => {
                        return (
                            <div className="grid gap-1">
                                {question?.options?.map((option, index) => (
                                    <div
                                        key={`Option ${index + 1}`}
                                        className="flex gap-16 mb-3 items-center justify-between"
                                    >
                                        <div className="flex w-full items-center gap-5">
                                            <InputType />
                                            <input
                                                className="border-b w-full  border-b-[#E5E7EB] outline-none placeholder:text-[1.4rem] text-[1.4rem] text-[#1F2937]"
                                                name={`${parentName}.options[${index}]`}
                                                type="text"
                                                placeholder={`Option ${index + 1}`}
                                                errors={errors}
                                                onChange={handleChange}
                                                value={option}
                                            />
                                        </div>

                                        <div className="flex items-center gap-12">
                                            {question.correctAnswer.includes(option) && <CheckIcon />}
                                            <div
                                                onClick={() => remove(index)}
                                                className="cursor-pointer p-4 bg-white rounded-[4px] border border-[#E5E7EB] shadow"
                                            >
                                                <CloseIcon />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <button
                                    className="flex focus:ring-0 mt-3 items-center focus:outline-none gap-5 max-w-fit"
                                    onClick={() => push('')}
                                >
                                    <InputType />
                                    <p className="text-[14px] text-[#6B7280] m-0">Add Option</p>
                                </button>
                            </div>
                        );
                    }}
                />
            )}

            {question.questionType === QUESTION_TYPE_VALUES.German && (
                <TextInput
                    label="Answer Field"
                    name={`${parentName}.answerField`}
                    type="text"
                    placeholder="Answer Field"
                    errors={errors}
                    onChange={handleChange}
                    value={question.answerField}
                />
            )}

            {/* {question.questionType === QUESTION_TYPE_VALUES.Upload && (
                <div className="flex items-center gap-[2rem]">
                    <Button type="button" onClick={handleOpenFileInuput} bgColor="#6366F1" color="#fff">
                        Add file
                    </Button>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        accept=".jpg,.jpeg,.png"
                        onChange={({ target: { files } }) => {
                            if (files && files[0]) {
                                // setProfilePictureUrl(URL.createObjectURL(files[0]));
                                setFieldValue('profilePicture.url', files[0]);
                            }
                        }}
                    />
                </div>
            )} */}

            <div className="flex justify-end">
                <div className="flex gap-[34px] items-center">
                    {showDelete && <DeleteIcon className="cursor-pointer" onClick={handleDelete} />}

                    {(question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ||
                        question.questionType === QUESTION_TYPE_VALUES.MultipleAnswer) && (
                        <p
                            onClick={() => setIsSelectAnswer(true)}
                            className="font-medium cursor-pointer text-[14px] text-[#3B82F6]"
                        >
                            Select Answer
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FillQuestion;
