import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { ReactComponent as Dropdown } from '../../../assets/icons/arrow-down2.svg';
import { Button } from '../../../components/buttons';
import Calender from '../../../components/Calender';
import { getExamTimetable } from '../../../redux/schedule/actions';
import CalenderDoc from '../calenderDoc';
import CalenderToggle from '../toggleCalender';

const MainContainer = tw.div`
    relative
    h-auto
    bg-white
    mx-auto
    lg:p-[2.4rem]
    md:p-[5.5rem]
    mt-[2.4rem]
    rounded-lg
`;

const ExamTimetable = () => {
    const dispatch = useDispatch();
    const [viewActions, setViewActions] = useState(false);
    const [documentView, setDocumentView] = useState(false);

    const { examTimetable } = useSelector((store) => store.schedule);
    const mondayTimetable = useMemo(
        () => examTimetable?.filter((timetable) => timetable.level === 100),
        [examTimetable],
    );
    const tuesdayTimetable = useMemo(
        () => examTimetable?.filter((timetable) => timetable.level === 200),
        [examTimetable],
    );
    const wednesdayTimetable = useMemo(
        () => examTimetable?.filter((timetable) => timetable.level === 300),
        [examTimetable],
    );
    const thursdayTimetable = useMemo(
        () => examTimetable?.filter((timetable) => timetable.level === 400),
        [examTimetable],
    );
    const fridayTimetable = useMemo(
        () => examTimetable?.filter((timetable) => timetable.level === 500),
        [examTimetable],
    );

    useEffect(() => {
        dispatch(getExamTimetable());
    }, [dispatch]);

    const handleDocClick = () => {
        setDocumentView(true);
        setViewActions(false);
    };

    const handleCalClick = () => {
        setDocumentView(false);
        setViewActions(false);
    };

    const data = examTimetable?.map((t) => {
        const examDate = new Date(t.examDate);
        const examStartTime = new Date(t.startTime);

        const newExamDate = new Date(t.examDate);
        const examEndTime = new Date(t.endTime);

        examDate.setHours(examStartTime.getUTCHours());
        examDate.setMinutes(examStartTime.getUTCMinutes());
        examDate.setSeconds(examStartTime.getUTCSeconds());

        newExamDate.setHours(examEndTime.getUTCHours());
        newExamDate.setMinutes(examEndTime.getUTCMinutes());
        newExamDate.setSeconds(examEndTime.getUTCSeconds());
        return {
            id: t._id,
            title: t.course,
            allDay: false,
            start: examDate,
            end: newExamDate,
        };
    });

    return (
        <>
            <MainContainer>
                <Button
                    className="ml-auto"
                    color="#6B7280"
                    border="1px solid #9CA3AF"
                    onClick={() => setViewActions(!viewActions)}
                >
                    View
                    <Dropdown className="ml-[13.15px]" />
                </Button>
                {viewActions && <CalenderToggle onDocumentClick={handleDocClick} onCalenderClick={handleCalClick} />}

                {documentView ? (
                    <CalenderDoc
                        heading1={'100 Level'}
                        heading2={'200 Level'}
                        heading3={'300 Level'}
                        heading4={'400 Level'}
                        heading5={'500 Level'}
                        mondayTimetable={mondayTimetable}
                        tuesdayTimetable={tuesdayTimetable}
                        wednesdayTimetable={wednesdayTimetable}
                        thursdayTimetable={thursdayTimetable}
                        fridayTimetable={fridayTimetable}
                    />
                ) : (
                    <Calender eventsList={data} />
                )}
            </MainContainer>
        </>
    );
};

export default ExamTimetable;
