import React, { useEffect, useState } from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { format } from 'date-fns';
import Avatar from 'react-avatar';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import { ReactComponent as People } from '../../../assets/icons/people2.svg';
// import { Loader } from '../../../components/loader';
import { GoBack } from '../../../components/go-back';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import {
    getStudentDetails,
    // getStudentResult,
} from '../../../redux/students/actions';
import { capitalizeFirstLetter, getDegreeInfo, getDegreeStructs } from '../../../utils';

import RegisteredCourses from './tabs/RegisteredCourses';
import Results from './tabs/Results';

const MainContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-2
    lg:grid-cols-3
    grid-flow-row
    gap-6
    mt-8
    mb-[4.4rem]
`;

const BasicContent = tw.div`
    grid
    md:grid-flow-col
    grid-flow-row
    my-8
    gap-4
`;

const SmallCard = tw.div`
    bg-white
    rounded-lg
    px-[6rem]
    mt-[1.6rem]
    flex
    flex-col
    justify-between
    items-center
`;

const DisplayCard = tw.div`
    bg-[#EEF2FF]
    px-[4.3rem]
    pb-[3.803rem]
    pt-[2.334rem]
    rounded-lg
`;

const Details = tw.div`
    bg-white
    lg:col-span-2
    col-span-1
    p-6
    rounded-lg
    mt-[1.6rem]
`;

const GreyText = styled(Text)`
    color: #9ca3af;
    line-height: 1.7rem;
    font-size: 1.2rem;
    text-align: ${({ align }) => align || 'left'};
`;

const DarkText = styled(Text)`
    color: #374151;
    line-height: 1.7rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    text-align: ${({ align }) => align || 'left'};
    text-transform: capitalize;
`;

const StudentDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { state } = useLocation();
    const { student } = state || {};
    const [, setTab] = useState(0);

    const [studentDetails, setStudentDetails] = useState(null);
    // const [resultsData, setResultsData] = useState(null)

    // const { data: studentResults, previousCgpa, currentGpa, cgpa } = resultsData || {};
    const { firstDegreeStruct, secondDegreeStruct, thirdDegreeStruct } = getDegreeStructs(
        studentDetails?.academicStructure,
    );
    const { firstDegree, secondDegree, thirdDegree } = getDegreeInfo(studentDetails, 'academicStructure');
    const fullName = `${studentDetails?.lastName} ${studentDetails?.firstName} ${studentDetails?.middleName || ''}`;

    const overviewItems = [
        {
            title: 'Full Name',
            value: fullName,
        },
        { title: capitalizeFirstLetter(firstDegreeStruct), value: firstDegree },
        { title: capitalizeFirstLetter(secondDegreeStruct), value: secondDegree },
        { title: capitalizeFirstLetter(thirdDegreeStruct), value: thirdDegree },
        { title: 'Matric Number', value: studentDetails?.matricNumber || '' },
        { title: 'Level', value: studentDetails?.level },
        { title: 'Graduate Level', value: studentDetails?.studentType },
        {
            title: 'Admission Date',
            value: studentDetails?.admissionYear ? format(new Date(studentDetails?.admissionYear), 'dd/MM/yyyy') : '',
        },
    ];

    // useEffect(() => {
    //     if (id && session && semester) {
    //         const semesterParam = semester === 'Second Semester' ? 'second' : 'first';

    //         // dispatch(getStudentResult(id, session, semester));
    //         const getCoursesData = async () => {
    //             const res = dispatch(getStudentCourses(id, session, semesterParam));
    //             if (res) {
    //                 return setCoursesData(res);
    //             }
    //             return setCoursesData({});
    //         };

    //         getCoursesData();
    //     }
    // }, [dispatch, id, session, semester]);

    useEffect(() => {
        if (id) {
            const getData = async () => {
                const res = await dispatch(getStudentDetails(id));
                if (res) {
                    return setStudentDetails(res.student);
                }
                return setStudentDetails(student);
            };

            getData();
        }
    }, [dispatch, id, student]);

    // if (isLoading) return <Loader />;

    return (
        <div className="pb-14">
            <GoBack title="Students" subTitle={`${fullName}`} />

            <MainContainer>
                <Details className="mt-[3rem] ">
                    <FlexRowSpaceBetween>
                        <FlexCentredRow>
                            <People className="mr-[1.719rem]" />
                            <PageTitle size="1.9rem" lineheight="2.8rem">
                                Student Details
                            </PageTitle>
                        </FlexCentredRow>
                    </FlexRowSpaceBetween>

                    <BasicContent>
                        {studentDetails?.profilePicture?.url ? (
                            <img
                                src={studentDetails?.profilePicture?.url}
                                alt="profile"
                                className="w-[15rem] h-[15rem] col-span-1"
                            />
                        ) : (
                            <Avatar name={fullName} size="150" textSizeRatio={1.75} />
                        )}

                        <div className="grid grid-cols-2 gap-y-4">
                            {overviewItems
                                .filter((item) => item.title)
                                .map((item) => (
                                    <div key={item.title}>
                                        <GreyText>{item.title}</GreyText>
                                        <DarkText>{item.value || ''}</DarkText>
                                    </div>
                                ))}
                        </div>
                    </BasicContent>
                </Details>
                <SmallCard>
                    <PageTitle size="1.9rem" weight="600" lineHeight="2.8rem" bottom="6.134rem" top="2.045rem">
                        CGPA
                    </PageTitle>
                    <div className="">
                        <DisplayCard>
                            <PageTitle size="6.9rem" weight="500" lineHeight="8.4rem">
                                {/* {cgpa || 0} */}0
                            </PageTitle>
                        </DisplayCard>
                        <Text top="1.193rem" size="1.4rem" weight="400" lineHeight="2.4rem" bottom="6.053rem">
                            Current Cummulative Grade Point Average
                        </Text>
                    </div>
                </SmallCard>
            </MainContainer>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink onClick={() => setTab(0)}>Registered Courses</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink onClick={() => setTab(1)}>Results</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <RegisteredCourses />
                    </CTabPane>
                    <CTabPane>
                        <Results
                            // studentResult={studentResults}
                            // numberOfCourses={numberOfCourses}
                            // totalUnits={totalUnits}
                            // previousCgpa={previousCgpa}
                            // semester={semester}
                            // session={session}
                            level={studentDetails?.level}
                            // currentCgpa={currentGpa}
                            // cgpa={cgpa}
                        />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </div>
    );
};

export default StudentDetail;
