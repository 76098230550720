import React, { useEffect, useState } from 'react';

import Avatar from 'react-avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as AllocatedIcon } from '../../assets/icons/allocated-course.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg';
import { ReactComponent as StudentNo } from '../../assets/icons/student-bg-green.svg';
import { ReactComponent as Tick } from '../../assets/icons/white-tick-green-bg.svg';
import { Button } from '../../components/buttons';
import Timetable from '../../components/dashboard/timetable';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredRow, FlexColumn, FlexDiv, FlexRowSpaceBetween } from '../../containers/ScreenContainers';
import { getSavedAcademicStructure } from '../../redux/academic-structure/actions';
import { getAllotedCourses } from '../../redux/alloted-courses/actions';
import { getNextActivities } from '../../redux/schedule/actions';
import { capitalizeFirstLetter } from '../../utils';

const MainContentContainer = tw.div`
    w-full
`;

const NoticeContainer = tw.div`
    rounded-[10px]
    bg-white
    w-[32rem]
    p-[16px]
`;

const DetailsContainer = tw.div`
    bg-[#4338CA]
    px-[3.393rem]
    rounded-[8px]
    py-[1.8rem]
    mb-[6.4rem]
`;

const Divider = tw.div`
    h-[1px]
    bg-[#6366F1]
    mt-[1.8rem]
    mb-[1.5rem]
`;

const StudentStatCard = tw.div`
    bg-white
    rounded-[8px]
    px-[1.6rem]
    py-[1.4rem]

`;

const RegistrationCard = tw.div`
    bg-white
    rounded-[8px]
    px-[1.6rem]
    py-[2.1rem]
    w-full
`;
const GridContainer = tw.div`
    grid
   grid-cols-2

`;

const NoticeItemsBox = tw.ul`
    gap-[2.5rem]
`;

const NoticeItem = tw.li`
    gap-[1.6rem]
    flex
    items-center
    cursor-pointer
`;

const ChatIconBox = tw.div`
    bg-[#DBEAFE]
    rounded-full
    p-[1rem]
`;

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.userData);

    const { allData } = useSelector((state) => state.allotedCourses);
    const { nextActivities } = useSelector((state) => state.schedule);
    const { staff, staffBio, staffExp, staffPub } = useSelector((store) => store.staff);
    const { activeSession } = useSelector((store) => store.admission);
    const { allReceived = [] } = useSelector((store) => store.communications);

    const [session] = useState(activeSession || '');

    const structure = user?.academicStructure?.split('-');
    const firstLevel = structure?.[0];
    const secondLevel = capitalizeFirstLetter(structure?.[1]);

    const handleClick = (item) => {
        if (item.label === 'Internal Memo') {
            navigate(`/communications/internal-memo/${item.id}`);
        } else if (item.label === 'Notice') {
            navigate(`/communications/notice/${item.id}`);
        } else if (item.label === 'Mail') {
            navigate(`/communications/mail/${item.id}`);
        }
    };

    useEffect(() => {
        if (!session) return;
        dispatch(getAllotedCourses(session));
    }, [dispatch, session]);

    useEffect(() => {
        dispatch(getNextActivities());
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    return (
        <FlexDiv className="gap-[2.4rem]">
            <MainContentContainer>
                <DetailsContainer>
                    <FlexRowSpaceBetween>
                        <FlexDiv className="gap-[1.6rem]">
                            {user?.profilePicture?.url ? (
                                <img
                                    src={user?.profilePicture?.url}
                                    alt="profile"
                                    className="rounded-full w-[12.1rem] h-[12.1rem]"
                                />
                            ) : (
                                <Avatar
                                    name={`${user?.surname}`}
                                    size="48"
                                    textSizeRatio={1.75}
                                    className="rounded-full"
                                />
                            )}
                            <FlexColumn>
                                <Text align="start" weight="600" size="1.6rem" color="white">
                                    {capitalizeFirstLetter(user?.firstName)} {capitalizeFirstLetter(user?.surname)}{' '}
                                    {capitalizeFirstLetter(user?.otherName)}
                                </Text>
                                <Text align="left" color="#BFDBFE" weight="500">
                                    {capitalizeFirstLetter(staff?.designation)}{' '}
                                    {capitalizeFirstLetter(user[`sub${secondLevel}`])}
                                </Text>
                            </FlexColumn>
                        </FlexDiv>
                    </FlexRowSpaceBetween>

                    <Divider />

                    <FlexDiv className="gap-4">
                        {user?.staffNumber ? (
                            <FlexColumn>
                                <Text size="1.2rem" color="#BFDBFE" align="start" transform="uppercase">
                                    Staff ID
                                </Text>
                                <Text weight="600" size="1.6rem" color="white" align="start">
                                    {user?.staffNumber || '-'}
                                </Text>
                            </FlexColumn>
                        ) : null}
                        {user[firstLevel] ? (
                            <FlexColumn>
                                <Text align="left" size="1.2rem" color="#BFDBFE" transform="uppercase">
                                    {capitalizeFirstLetter(firstLevel)}
                                </Text>
                                <Text weight="600" size="1.6rem" color="white" align="start">
                                    {user[firstLevel]}
                                </Text>
                            </FlexColumn>
                        ) : null}
                    </FlexDiv>
                </DetailsContainer>

                <GridContainer className="gap-[2.4rem] mb-[2.4rem]">
                    <StudentStatCard>
                        <FlexColumn className="gap-[1.3rem]">
                            <AllocatedIcon />

                            <Text align="left" size="2.8rem" weight="600">
                                {allData?.numberOfCourses}
                            </Text>
                            <Text weight="500" align="left" color="#A0AEC0" bottom="4px">
                                Allotted Courses
                            </Text>
                        </FlexColumn>
                    </StudentStatCard>
                    <StudentStatCard>
                        <FlexColumn className="gap-[1.3rem]">
                            <StudentNo />

                            <Text align="left" size="2.8rem" weight="600">
                                {allData?.totalNumberOfStudent}
                            </Text>
                            <Text weight="500" align="left" color="#A0AEC0" bottom="4px">
                                Total Number of Students
                            </Text>
                        </FlexColumn>
                    </StudentStatCard>
                    <StudentStatCard>
                        <FlexColumn className="gap-[1.3rem]">
                            <Text align="left" size="1.6rem" weight="600" lineHeight="2.8rem">
                                Time-table
                            </Text>
                            <Timetable />
                        </FlexColumn>
                    </StudentStatCard>
                    <StudentStatCard>
                        <FlexColumn className="gap-[2rem]">
                            <Text align="left" size="1.6rem" weight="600" lineHeight="2.8rem">
                                Upcoming...
                            </Text>
                            {nextActivities?.map((next, _id) => (
                                <div key={next?._id}>
                                    <Text
                                        align="left"
                                        size="1.9rem"
                                        weight="600"
                                        lineHeight="2.8rem"
                                        color="#1E40AF"
                                        bottom="2rem"
                                        wrap="wrap"
                                    >
                                        {next?.activity}
                                    </Text>
                                    <FlexCentredRow className="gap-[1rem]">
                                        <Text align="left" size="1.2rem" weight="500" lineHeight="1.7rem" wrap="wrap">
                                            {next?.from?.slice(0, 10)}
                                        </Text>
                                        <Text size="1.2rem" weight="500" lineHeight="1.7rem" wrap="wrap">
                                            to
                                        </Text>
                                        <Text align="left" size="1.2rem" weight="500" lineHeight="1.7rem" wrap="wrap">
                                            {next?.to?.slice(0, 10)}
                                        </Text>
                                    </FlexCentredRow>
                                </div>
                            ))}
                        </FlexColumn>
                    </StudentStatCard>
                </GridContainer>

                <RegistrationCard>
                    {staffBio?.status && staffExp?.status && (staffPub?.status || staffPub?.length > 0) ? (
                        <FlexRowSpaceBetween>
                            <Text weight="600" align="left" size="1.6rem" lineHeight="2.8rem">
                                Staff Profile
                            </Text>
                            <Button
                                onClick={() => navigate('/dashboard/view-details')}
                                className="border border-[#E5E7EB]"
                            >
                                View Details
                                <Tick className="ml-[0.8rem]" />
                            </Button>
                        </FlexRowSpaceBetween>
                    ) : (
                        <FlexRowSpaceBetween>
                            <Text weight="600" align="left" size="1.6rem" lineHeight="2.8rem">
                                Complete your staff profile
                            </Text>

                            <Button onClick={() => navigate('/dashboard/bio-data')} bgColor="#6366F1" color="#FFF">
                                Start Here
                            </Button>
                        </FlexRowSpaceBetween>
                    )}
                </RegistrationCard>
            </MainContentContainer>

            <NoticeContainer>
                <FlexRowSpaceBetween className="mb-[1.6rem]">
                    <Text align="left" weight="600" size="1.6rem">
                        Notice Board
                    </Text>
                </FlexRowSpaceBetween>

                <NoticeItemsBox>
                    {allReceived?.map((comms, idx) => (
                        <NoticeItem key={idx} onClick={() => handleClick(comms)}>
                            <ChatIconBox>
                                <ChatIcon />
                            </ChatIconBox>

                            <FlexColumn>
                                <FlexDiv>
                                    <Text align="left" weight="600" size="1.2rem" wrap="wrap">
                                        {comms?.label}
                                    </Text>
                                    <Text align="left" size="1.2rem" weight="400" wrap="wrap">
                                        {' '}
                                        -{comms?.subject?.slice(0, 20)}
                                    </Text>
                                </FlexDiv>
                                <Text align="left" weight="400" color="#6B7280" size="1.2rem" wrap="wrap">
                                    {comms?.senderUnit ? comms?.senderUnit : comms?.sender}. {comms?.time}
                                </Text>
                            </FlexColumn>
                        </NoticeItem>
                    ))}
                </NoticeItemsBox>
            </NoticeContainer>
        </FlexDiv>
    );
};

export default Dashboard;
