import React from 'react';

import { Form, Formik } from 'formik';
// import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PaperIcon } from '../../../assets/icons/paper.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import { GoBack } from '../../../components/go-back';
import DateInput from '../../../components/inputs/date-input';
import Select from '../../../components/inputs/new-select';
import TextAreaInput from '../../../components/inputs/text-area';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { PageTitle, Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editAttendance } from '../../../redux/attendance/actions';
import { formatTime } from '../../../utils';

import { ValidationSchema } from './ValidationSchema';

const FirstStepBox = tw.div`
    bg-white
    p-[3.5rem]
    rounded-2xl
    max-w-[1000px]

`;
const GridRows = tw.div`
    grid
    grid-cols-2
    gap-x-[32px]
    gap-y-[24px]
`;

const attendanceTypes = [
    { name: 'Manual (Attendance taken outside the platform)', value: 'Manual' },
    // { name: 'Text and Upload', value: 'Text and upload' },
    // { name: 'Text Only', value: 'Text only' },
    // { name: 'Upload Only', value: 'Upload only' },
];

const continousAssessmentOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
];

const UpdateAttendance = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: attendanceId } = useParams();
    const { state } = useLocation();

    const { course, attendance: details, session } = state || {};
    const { courseCode, courseTitle, _id: courseId } = course || {};

    const { isLoading } = useSelector((state) => state.attendance);

    return (
        <>
            <GoBack title={`Attendance / ${courseCode}(${courseTitle})`} subTitle="Edit Attendance" />
            <PageTitle align="left" top="3.2rem" bottom="3.2rem">
                Edit Attendance
            </PageTitle>

            <Formik
                initialValues={{
                    title: details?.title || '',
                    attendanceType: details?.attendanceType || '',
                    continousAssessmentOption: details?.continousAssessmentOption || '',
                    description: details?.description || '',
                    date: details?.date || '',
                    startTime: details?.startTime || '',
                    endTime: details?.endTime || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const payload = {
                        ...values,
                        startTime: formatTime(values.startTime),
                        endTime: formatTime(values.endTime),
                    };
                    const res = await dispatch(editAttendance(session, courseId, attendanceId, payload));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => (
                    <Form>
                        <FirstStepBox>
                            <FlexCentredRow className="gap-2 mb-8">
                                <PaperIcon />
                                <Text weight="600" size="1.6rem">
                                    Attendance
                                </Text>
                            </FlexCentredRow>

                            <GridRows>
                                <Select
                                    name="attendanceType"
                                    objProp="name"
                                    label="Attendance Type"
                                    error={errors.attendanceType}
                                    placeholder="Attendance Type"
                                    data={attendanceTypes}
                                    onChange={(selected) => {
                                        setFieldValue('attendanceType', selected[0].value);
                                    }}
                                    useComponentState={false}
                                    passedSelectedItems={
                                        values?.attendanceType ? [{ name: values?.attendanceType }] : null
                                    }
                                />
                                <TextInput
                                    name="title"
                                    label="Attendance Title"
                                    errors={errors}
                                    placeholder="Enter Attendance Title"
                                    value={values?.title}
                                    onChange={handleChange}
                                />
                                <Select
                                    name="continousAssessmentOption"
                                    label="Continous Assessment"
                                    objProp="name"
                                    passedSelectedItems={values.continousAssessmentOption ? 'Yes' : 'No'}
                                    placeholder="Select an Option"
                                    data={continousAssessmentOptions}
                                    onChange={(selected) => {
                                        setFieldValue('continousAssessmentOption', selected[0].value);
                                    }}
                                    error={errors.continousAssessmentOption}
                                />
                                <DateInput
                                    label="Lecture Date"
                                    name="date"
                                    onChange={handleChange}
                                    value={values.date.slice(0, 10)}
                                    errors={errors}
                                />
                                <TextInput
                                    label="Lecture Start Time"
                                    name="startTime"
                                    type="time"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.startTime}
                                />
                                <TextInput
                                    label="Lecture End Time"
                                    name="endTime"
                                    type="time"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.endTime}
                                />
                                <TextAreaInput
                                    name="description"
                                    label="Description"
                                    value={values.description}
                                    errorMessage={errors.description}
                                    placeholder="Description"
                                    height="8rem"
                                    onChange={handleChange}
                                />
                            </GridRows>
                            <FlexRowEnd className="gap-6 mt-12">
                                <Button onClick={() => navigate(-1)} border="1px solid #D1D5DB" type="button">
                                    Cancel
                                </Button>
                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Update Attendance
                                </LoadingButton>
                            </FlexRowEnd>
                        </FirstStepBox>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default UpdateAttendance;
