import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';

import { GET_HANDBOOK, GET_HANDBOOK_BY_ID, LOADING, STOP_LOADING } from './slice';

export const getHandbook = (session) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}handbook/:campusId/get_session_handbook/${session}`);
        dispatch(GET_HANDBOOK(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHandbookById = (id, session) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}handbook/:campusId/get_session_handbook_by_id/${session}/${id}`,
        );
        dispatch(GET_HANDBOOK_BY_ID(response.data.data));
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.Message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
