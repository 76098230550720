import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as SendIcon } from '../../../assets/icons/send.svg';
import { Button } from '../../../components/buttons';
// import { AllFilter } from '../../../components/filter-select';
import { GoBack } from '../../../components/go-back';
import Checkbox from '../../../components/inputs/checkbox';
import SearchInput from '../../../components/inputs/search-input';
import ItemsPerPage from '../../../components/items-per-page';
// import SendToCAPopup from '../../../components/popups/send-to-ca';
import { Text } from '../../../containers/MesssageContainers';
import {
    FlexCentredRow,
    FlexColumn,
    FlexDiv,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../containers/ScreenContainers';
import { getCaBroadsheet } from '../../../redux/result/actions';
import { getDegreeInfo } from '../../../utils';

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const overviewItems = [
    {
        title: 'Version',
        key: 'version',
    },
    {
        title: 'Description',
        key: '',
    },
    {
        title: 'Date Created',
        key: 'versionDate',
    },
];

const CaBroadsheet = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { courseCode, courseTitle, _id: courseId } = course || {};

    const { isLoading } = useSelector((store) => store.result);
    const { firstDegreeOptionsStr, secondDegreeOptionsStr } = useSelector((store) => store.academicStructure);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [broadsheet, setBroadsheet] = useState([]);
    // const [openModal, setOpenModal] = useState('');

    const totalPages = Math.ceil(broadsheet?.length / itemsPerPage);

    const fields = [
        {
            label: <Checkbox />,
            key: 'action',
        },
        {
            label: 'Full Name',
            key: 'name',
        },
        {
            label: 'Matric Number',
            key: 'matricNumber',
        },
        {
            label: firstDegreeOptionsStr,
            key: 'firstDegree',
            _style: { minWidth: '250px' },
        },
        {
            label: secondDegreeOptionsStr,
            key: 'secondDegree',
            _style: { minWidth: '200px' },
        },
        {
            label: 'Attendance',
            key: 'attendanceScore',
        },
        {
            label: 'Quiz',
            key: 'quizScore',
        },
        {
            label: 'Test',
            key: 'testScore',
        },
        {
            label: 'Assignment',
            key: 'assignmentScore',
        },
        {
            label: 'Total CA',
            key: 'totalScore',
        },
    ];

    // const handleSendToCA = async () => {
    //     const res = await dispatch(sendCaToBroadsheet(courseId, session));
    //     if (res) {
    //         setOpenModal('');
    //     }
    // };

    useEffect(() => {
        if (!session) return;
        const fetchBroadsheet = async () => {
            const data = await dispatch(getCaBroadsheet(session, courseId));
            setBroadsheet(data || []);
        };

        fetchBroadsheet();
    }, [courseId, dispatch, session]);

    return (
        <>
            <FlexRowSpaceBetween className="gap-[1rem">
                <GoBack title="CA Broadsheet" subTitle={`${courseCode} (${courseTitle})`} />
                <FlexDiv className="gap-4">
                    {/* <Button onClick={() => setOpenModal('send-to-ca')} disabled={isSentToBroadsheet}> */}
                    <Button>
                        <SendIcon className="mr-[1.15rem] text-[#059669]" />
                        Send to Exam Broadsheet
                    </Button>
                </FlexDiv>
            </FlexRowSpaceBetween>

            <div className="bg-white w-full p-[24px] mt-12 rounded-lg">
                <Text weight="600" size="1.9rem" align="left">
                    Overview
                </Text>

                <div className="flex justify-between mt-8">
                    {overviewItems.map((item) => (
                        <FlexColumn className="gap-1">
                            <Text weight="500" align="left" size="1.2rem" color="#9CA3AF">
                                {item.title}
                            </Text>
                            {/* <Text weight="500" align="left" size="1.4rem" color="#4B5563">
                                {item.key === 'versionDate'
                                    ? formatDate(selectedVersion?.versionDate)
                                    : selectedVersion[item.key]}
                            </Text> */}
                        </FlexColumn>
                    ))}
                </div>
            </div>

            <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
                <FilterContainer>
                    <FlexCentredRow>
                        <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                    </FlexCentredRow>

                    {/* <RelativeContainer> */}
                    {/* <AllFilter
                        // showClear={currentFilterCategory}
                        // items={FILTER_OPTIONS.map((item) => ({
                        //     name: item,
                        //     click: () => {
                        //         handleSelectCategories(item);
                        //         setCurrentFilterCategory(item);
                        //         setOpenModal('options-popup-main');
                        //     },
                        // }))}
                        // handleReset={handleReset}
                        ///> */}
                    {/* <FilterPopup
                            open={openModal === 'options-popup-main'}
                            isLoading={facultyLoading || collegeLoading}
                            close={() => setOpenModal(null)}
                            items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                        /> */}
                    {/* </RelativeContainer> */}
                </FilterContainer>

                <CDataTable
                    items={broadsheet || []}
                    fields={fields}
                    // striped
                    selectable
                    responsive
                    itemsPerPage={itemsPerPage}
                    activePage={currentPage || 1}
                    clickableRows
                    loading={isLoading}
                    scopedSlots={{
                        action: (item) => (
                            <td>
                                <Checkbox />
                            </td>
                        ),
                        name: (item) => <td style={{ color: '#2563EB' }}>{item.name}</td>,
                        matricNumber: (item) => <td>{item.matricNumber || ''}</td>,
                        firstDegree: (item) => {
                            const { firstDegree } = getDegreeInfo(item, 'academicStructure');
                            return <td>{firstDegree || ''}</td>;
                        },
                        secondDegree: (item) => {
                            const { secondDegree } = getDegreeInfo(item, 'academicStructure');
                            return <td>{secondDegree || ''}</td>;
                        },
                        quizScore: (item) => <td>{item.quizScore || ''}</td>,
                    }}
                />
                <ItemsPerPageCont>
                    <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                    <CPagination
                        activePage={currentPage || 1}
                        onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                        pages={totalPages || 1}
                        align="end"
                    />
                </ItemsPerPageCont>
            </div>

            {/* <SendToCAPopup
                type="Assignment"
                show={openModal === 'send-to-exam'}
                confirmAction={() => handleSendToCA()}
                isLoading={isLoading}
                close={() => setOpenModal('')}
                // version={selectedVersion?.version}
            /> */}
        </>
    );
};

export default CaBroadsheet;
