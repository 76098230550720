import React, { useEffect, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Arrow } from '../assets/icons/arrow-down.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import menuIcon from '../assets/icons/menu.svg';
import { useWindowSize } from '../hooks/useWindowSize';

import { navLinks } from './navLinks';

const NavItem = tw.li`
    font-medium
    text-[1.4rem]
    leading-6
    flex
    items-center
    lg:gap-x-[1.115rem]
    gap-x-2
    cursor-pointer
    px-[1.315rem]
    pt-[13.98px]
    pb-[14.15px]
`;

const LinkItem = tw(NavItem)`
    // rounded-[8px]
`;

const Sidebar = ({ open, setOpen }) => {
    const location = useLocation();

    const [width] = useWindowSize();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');

    const handleSelect = (link) => {
        if (!open) setOpen(true);

        if (selectedTitle === link.title) {
            setOpenDropdown(false);
            setSelectedTitle('');
        } else {
            setSelectedTitle(link.title);
            setOpenDropdown(true);
        }
    };

    const isSelected = (link) => {
        if (location.pathname === link || (location.pathname.startsWith(link) && link !== '/')) return true;
        return false;
    };

    const getSelectedClass = (link) => {
        return isSelected(link) ? 'text-[#6366F1]' : 'text-[#1F2937]';
    };

    useEffect(() => {
        width && width <= 1300 && setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        if (!open) {
            setOpenDropdown(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <aside
            className={`${
                open ? 'w-[29rem] px-[2.4rem]' : 'min-w-[content] px-[.6rem]'
            } relative duration-300 h-screen pt-[5rem] pb-[5rem] z-10 bg-white shadow-md`}
        >
            {open ? (
                <CloseIcon
                    className="absolute right-[24px] top-[20px] cursor-pointer"
                    onClick={() => setOpen(false)}
                />
            ) : (
                <div>
                    <img
                        className="absolute left-[20px] top-[20px] cursor-pointer"
                        src={menuIcon}
                        alt="menu"
                        onClick={() => setOpen(!open)}
                    />
                </div>
            )}

            {navLinks.map((link, index) => {
                return (
                    <div key={link.title}>
                        {!link.subMenu?.length > 0 ? (
                            <ul>
                                <Link to={link.to}>
                                    <LinkItem className={`${!open && 'justify-center'} ${getSelectedClass(link.to)}`}>
                                        <link.src fill={isSelected(link.to) ? '#6366F1' : '#1F2937'} />
                                        {open && <span>{link.title}</span>}
                                    </LinkItem>
                                </Link>
                            </ul>
                        ) : (
                            <div>
                                <NavItem className={`${!open && 'justify-center'}`} onClick={() => handleSelect(link)}>
                                    <link.src fill="#1F2937" />
                                    {open && <span className="mb-0 text-[#1F2937]">{link.title}</span>}
                                    {open && (
                                        <Arrow
                                            className={openDropdown && selectedTitle === link.title ? '' : '-rotate-90'}
                                        />
                                    )}
                                </NavItem>
                                {openDropdown && selectedTitle === link.title && (
                                    <ul>
                                        {link?.subMenu?.map((item, i) => (
                                            <Link to={item.to} key={i}>
                                                <LinkItem
                                                    className={`${!open && 'justify-center'}
                                                      ml-4 ${getSelectedClass(item.to)}`}
                                                >
                                                    <span className="w-full">{item.title}</span>
                                                </LinkItem>
                                            </Link>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </aside>
    );
};

export default Sidebar;
