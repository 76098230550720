import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PayrollIcon } from '../../../assets/icons/payroll.svg';
import { LoadingButton } from '../../../components/buttons';
import { CancelButton } from '../../../components/forms/sharedStyles';
import Select from '../../../components/inputs/new-select';
import TextInput from '../../../components/inputs/text-input-with-formik';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { createPayroll, editPayroll, getPayrollDetails, getAllBanksFromPaystack } from '../../../redux/staff/actions';
const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;
const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
    mt-[3rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Type = ['savings', 'current'];

const Payroll = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, payrollDetails } = useSelector((state) => state.staff);

    const details = payrollDetails?.[0];

    const [allBanks, setAllBanks] = useState([]);
    const [hasPayroll, setHasPayroll] = useState(false);

    useEffect(() => {
        const getBanks = async () => {
            const res = await dispatch(getAllBanksFromPaystack());
            setAllBanks(res);
        };
        getBanks();
    }, [dispatch]);

    useEffect(() => {
        const fetchPayrollDetails = async () => {
            const payrollDetail = await dispatch(getPayrollDetails());

            const details = payrollDetail?.[0];
            if (details) {
                setHasPayroll(!!details);
            }
        };
        fetchPayrollDetails();
        // eslint-disable-next-line
    }, [dispatch]);

    return (
        <>
            <MainContainer>
                <Formik
                    initialValues={{
                        accountName: details?.accountName || '',
                        accountNumber: details?.accountNumber || '',
                        bankName: details?.bankName || '',
                        accountType: details?.accountType || '',
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values) => {
                        let res;
                        if (details && hasPayroll) {
                            res = await dispatch(editPayroll(values, details?._id));
                        } else {
                            res = await dispatch(createPayroll(values));
                        }
                        if (res) {
                            toast.success('Payroll Saved Successfully!');
                        }
                    }}
                >
                    {({ errors, handleChange, values, setFieldValue }) => (
                        <Form>
                            <FlexCentredRow className="mb-[3.2rem] mt-[1.2rem] gap-[0.715rem]">
                                <PayrollIcon />
                                <Title> Payroll</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] grid grid-cols-1 ">
                                <Select
                                    name="bankName"
                                    objProp="name"
                                    placeholder="Select Bank"
                                    label="Bank"
                                    searchable
                                    data={allBanks || []}
                                    onChange={(selected) => {
                                        setFieldValue('bankName', selected?.[0]?.name);
                                    }}
                                    error={errors.bankName}
                                    passedSelectedItems={values?.bankName}
                                />
                                <TextInput
                                    name="accountName"
                                    type="text"
                                    label="Account Name"
                                    placeholder="input Account Name"
                                    value={values.accountName}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <Select
                                    name="accountType"
                                    objProp="accountType"
                                    placeholder="Select Account Type"
                                    label="Account Type"
                                    data={Type.map((item) => ({ accountType: item }))}
                                    onChange={(selected) => {
                                        setFieldValue('accountType', String(selected[0].accountType));
                                    }}
                                    error={errors.accountType}
                                    passedSelectedItems={values.accountType}
                                />
                                <TextInput
                                    name="accountNumber"
                                    type="text"
                                    label="Account Number"
                                    placeholder="Input Account Number"
                                    value={values.accountNumber}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                            </div>
                            <FlexContainer>
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton disabled={isLoading} type="submit" loading={isLoading}>
                                    Save Changes
                                </LoadingButton>
                            </FlexContainer>
                        </Form>
                    )}
                </Formik>
            </MainContainer>
        </>
    );
};

export default Payroll;
