import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { LoadingButton } from '../../../components/buttons';
import Checkbox from '../../../components/inputs/checkbox';
import RadioInput from '../../../components/inputs/radio';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { QUESTION_TYPE_VALUES } from '../create-assignment/utils';

const GradedList = ({ questions = [] }) => {
    const navigate = useNavigate();

    return (
        <>
            <ul className=" space-y-6 mt-10">
                {questions?.map((question, index) => {
                    const InputType =
                        question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ? RadioInput : Checkbox;

                    const isCorrect = question.isCorrect === 'correct';

                    return (
                        <li key={String(question?.questionId)} className="bg-white rounded-[16px] py-[3.6rem]">
                            <FlexRowSpaceBetween className="px-[3.2rem]">
                                <Text align="left" weight="600">{`Question ${index + 1}`}</Text>
                                <Text weight="600">
                                    {question.studentScore}/{question.maxMarks}
                                </Text>
                            </FlexRowSpaceBetween>

                            <div className="px-[3.2rem]">
                                <Text
                                    align="left"
                                    left="1.6rem"
                                    color={isCorrect ? '#059669' : '#EF4444'}
                                    top="1.6rem"
                                    bottom="2rem"
                                >
                                    {question.questionText}
                                </Text>
                            </div>

                            <div className="grid gap-3">
                                {question.options.map((option) => {
                                    const isSelected = question.studentAnswer?.includes(option);

                                    return (
                                        <div
                                            key={option}
                                            style={{
                                                backgroundColor:
                                                    isCorrect && isSelected
                                                        ? 'rgba(5, 150, 105, .2)'
                                                        : !isCorrect && isSelected
                                                        ? 'rgba(239, 68, 68, .2)'
                                                        : '',
                                            }}
                                            className={`px-[3.2rem] py-2 flex justify-between items-center`}
                                        >
                                            <InputType label={option} checked={isSelected} />

                                            {isCorrect && isSelected ? <CheckmarkIcon /> : null}
                                            {!isCorrect && isSelected ? <CloseIcon className="text-[#EF4444]" /> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            {!isCorrect && (
                                <div className="px-[3.2rem] mt-8">
                                    <Text align="left" weight="600">
                                        Correct Answer
                                    </Text>
                                    <Text align="left">{question.correctAnswer.join(', ')}</Text>
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>

            <div className="flex mt-10 justify-end">
                <LoadingButton onClick={() => navigate(-1)}>Close</LoadingButton>
            </div>
        </>
    );
};

export default GradedList;
