import React from 'react';

import { useNavigate } from 'react-router-dom';

// import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
// import { ReactComponent as ProfileIcon } from '../../assets/icons/people2.svg';
// import { ReactComponent as StaffArenaIcon } from '../../assets/icons/staff-arena.svg';
import { Text } from '../../containers/MesssageContainers';
import { FlexCentredCol, FlexCentredRow } from '../../containers/ScreenContainers';

const ProfileLinks = ({ userType, appointmentId }) => {
    const navigate = useNavigate();

    const profileLinks = [
        // ...(userType === 'staff'
        //     ? [{ icon: <ProfileIcon fill="#1F2937" />, title: 'Profile', link: '/staff-arena/profile' }]
        //     : []),
        // { icon: <SettingsIcon />, title: 'Preference', link: '' },
        // ...(userType === 'staff'
        //     ? [
        //           {
        //               icon: <StaffArenaIcon />,
        //               title: 'Staff Arena',
        //               link: appointmentId ? '/staff-arena/office/inbox' : '/staff-arena/communications/received',
        //           },
        //       ]
        //     : []),
    ];

    return (
        <FlexCentredCol className="gap-[1.6rem] pt-[3.6rem]">
            {profileLinks?.map((item, index) => (
                <FlexCentredRow
                    onClick={() => navigate(item?.link)}
                    key={item?.title}
                    className={`${
                        profileLinks?.length - 1 === index ? 'border-y-[0.5px] border-y-[#6B7280] py-[1.6rem]' : ''
                    } gap-[10px]  px-[2.4rem]`}
                >
                    {item.icon}
                    <Text align="left" weight="400" size="1.6rem" color="#1F2937">
                        {item.title}
                    </Text>
                </FlexCentredRow>
            ))}
        </FlexCentredCol>
    );
};

export default ProfileLinks;
