import React, { useState, useEffect } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import { FlexRowSpaceBetween, ItemsPerPageCont } from '../../../../containers/ScreenContainers';
import useDebounce from '../../../../hooks/useDebounce';
import useSearch from '../../../../hooks/useSearch';
import { getAssignment } from '../../../../redux/assignment/actions';

const fields = [
    {
        key: 'action',
        label: <Checkbox />,
    },
    {
        label: 'Full Name',
        key: 'name',
    },
    {
        label: 'Matric No.',
        key: 'matricNumber',
    },
    {
        label: 'Submitted',
        key: 'submitted',
    },
    {
        label: 'Graded',
        key: 'graded',
    },
    {
        label: 'Score',
        key: 'score',
    },
];
const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Students = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id: assignmentId } = useParams();

    const { assignment } = state || {};

    const { isLoading } = useSelector((state) => state.assignment);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [students, setStudents] = useState([]);
    const filteredStudents = useSearch(debouncedSearchValue, students, ['firstName', 'lastName', 'middleName']);

    const totalPages = Math.ceil(filteredStudents?.length / itemsPerPage);

    useEffect(() => {
        const getDetails = async () => {
            const data = await dispatch(getAssignment(assignmentId));
            if (data) {
                setStudents(data.students);
            }
        };

        getDetails();
    }, [assignmentId, dispatch]);

    return (
        <div className="mt-[3.2rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
            <FilterContainer>
                <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
            </FilterContainer>

            <CDataTable
                items={filteredStudents || []}
                fields={fields}
                selectable
                responsive
                itemsPerPage={itemsPerPage}
                activePage={currentPage || 1}
                clickableRows
                loading={isLoading}
                scopedSlots={{
                    action: (item) => (
                        <td>
                            <Checkbox />
                        </td>
                    ),
                    name: (item) => (
                        <td
                            style={{ color: '#2563EB' }}
                            onClick={() =>
                                navigate(`/assignments/details/submissions/${item._id}`, {
                                    state: { student: item, assignment },
                                })
                            }
                        >
                            {item.lastName} {item.middleName || ''} {item.firstName}
                        </td>
                    ),
                    submitted: (item) => (
                        <td>
                            <span className={item.submitted ? 'text-[#10B981]' : 'text-[#EF4444]'}>
                                {item.submitted ? 'Yes' : 'No'}
                            </span>
                        </td>
                    ),
                    graded: (item) => (
                        <td>
                            <span className={item.graded ? 'text-[#10B981]' : 'text-[#EF4444]'}>
                                {item.graded ? 'Yes' : 'No'}
                            </span>
                        </td>
                    ),
                }}
            />
            <ItemsPerPageCont>
                <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />
                <CPagination
                    activePage={currentPage || 1}
                    onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                    pages={totalPages || 1}
                    align="end"
                />
            </ItemsPerPageCont>
        </div>
    );
};
export default Students;
