import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Text } from '../../containers/MesssageContainers';

const daysOftheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const Timetable = () => {
    const { mondayTimetable, tuesdayTimetable, wednesdayTimetable, thursdayTimetable, fridayTimetable } = useSelector(
        (store) => store.schedule,
    );
    const [timetableData, setTimetableData] = useState([]);
    const currentDay = format(new Date(), 'EEEE');
    const [selectedDay, setSelectedDay] = useState(currentDay?.toLowerCase());
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedDay === 'monday') {
            setTimetableData(mondayTimetable);
        } else if (selectedDay === 'tuesday') {
            setTimetableData(tuesdayTimetable);
        } else if (selectedDay === 'wednesday') {
            setTimetableData(wednesdayTimetable);
        } else if (selectedDay === 'thursday') {
            setTimetableData(thursdayTimetable);
        } else if (selectedDay === 'friday') {
            setTimetableData(fridayTimetable);
        } else if (selectedDay === 'saturday') {
            setTimetableData([]);
        } else {
            setTimetableData([]);
        }
        //eslint-disable-next-line
    }, [selectedDay]);

    return (
        <div>
            {timetableData?.slice(0, 3)?.map((timetable) => (
                <div
                    onClick={() => navigate('/schedules')}
                    className="flex items-center justify-between border-b-[0.3px] border-b-[#000] cursor-pointer"
                >
                    <Text size="1.2rem" weight="500" align="left">
                        {timetable.course}
                    </Text>
                    <Text size="1rem" weight="400" align="left">
                        {`${new Date(timetable.startTime).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        })} - 
                        ${new Date(timetable.endTime).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                        })}`}
                    </Text>
                    <Text size="1rem" weight="400" align="left">
                        {timetable.venue}
                    </Text>
                </div>
            ))}
            <div className="flex items-center justify-between pt-[1rem]">
                {daysOftheWeek.map((days, index) => (
                    <div
                        className={` w-[1.7rem] h-[1.7rem] text-center flex items-center justify-center cursor-pointer ${
                            selectedDay === days ? 'rounded-full text-white bg-[#6366F1]' : null
                        } `}
                        onClick={() => setSelectedDay(days)}
                        key={index}
                    >
                        {days.charAt(0).toUpperCase()}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Timetable;
