import React from 'react';

import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit2.svg';
import { Button, LoadingButton } from '../../../components/buttons';
import Checkbox from '../../../components/inputs/checkbox';
import RadioInput from '../../../components/inputs/radio';
import { Text } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { markStudentAssignment } from '../../../redux/assignment/actions';
import { QUESTION_TYPE_VALUES } from '../create-assignment/utils';

const ReadyToGradeList = ({ questions = [], assignmentId, studentId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <>
            <Formik
                initialValues={{
                    answers: questions.map((question) => ({
                        questionId: question.questionId,
                        score: null,
                    })),
                }}
                enableReinitialize
                onSubmit={async (values, actions, errors) => {
                    const { question, ...otherValues } = values;

                    const res = await dispatch(markStudentAssignment(assignmentId, studentId, otherValues));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue, resetForm }) => (
                    <Form>
                        <ul className=" space-y-6 mt-10">
                            {questions?.map((question, index) => {
                                const InputType =
                                    question.questionType === QUESTION_TYPE_VALUES.SingleAnswer ? RadioInput : Checkbox;

                                return (
                                    <li
                                        key={String(question?.questionId)}
                                        className="bg-white rounded-[16px] py-[3.6rem]"
                                    >
                                        <FlexRowSpaceBetween className="px-[3.2rem]">
                                            <Text align="left" weight="600">{`Question ${index + 1}`}</Text>

                                            {values.answers[index]?.score !== null && (
                                                <div className="flex gap-4 items-center">
                                                    <EditIcon />
                                                    <Text weight="500" color="#6B7280" right="3.2rem">
                                                        Edit Score
                                                    </Text>
                                                    <input
                                                        className="border max-w-fit border-[#9CA3AF] text-center px-2 w-[43px] h-[36px] rounded-lg outline-none placeholder:text-[1.4rem text-[1.4rem] text-[#1F2937]"
                                                        type="text"
                                                        errors={errors}
                                                        onChange={(e) =>
                                                            setFieldValue(`answers[${index}].score`, e.target.value)
                                                        }
                                                        value={values.answers[index]?.score}
                                                    />
                                                    <Text weight="600">/{question.maxMarks}</Text>
                                                </div>
                                            )}
                                        </FlexRowSpaceBetween>

                                        <Text align="left" left="4.8rem" top="1.6rem" bottom="2rem">
                                            {question.questionText}
                                        </Text>

                                        <div className="grid gap-3 px-[3.2rem]">
                                            {question.options.map((option) => {
                                                const isSelected = question.studentAnswer?.includes(option);
                                                return (
                                                    <div
                                                        key={option}
                                                        className={` py-2 flex justify-between items-center`}
                                                    >
                                                        <InputType label={option} checked={isSelected} />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {(question.questionType === QUESTION_TYPE_VALUES.German ||
                                            question.questionType === QUESTION_TYPE_VALUES.ShortAnswer ||
                                            question.questionType === QUESTION_TYPE_VALUES.LongAnswer) && (
                                            <div
                                                style={{ backgroundColor: 'rgba(156, 163, 175, .1)' }}
                                                className="pl-[3.2rem] pr-40 mb-[2.2rem] pb-2 pt-3"
                                            >
                                                <p className="border-b border-b-[#9CA3AF] pb-2 text-[1.4rem] text-[#1F2937]">
                                                    {question?.studentAnswer || ''}
                                                </p>
                                            </div>
                                        )}

                                        <div className="flex justify-end gap-4 px-[3.2rem]">
                                            <Button
                                                bgColor="#EF4444"
                                                color="white"
                                                type="button"
                                                onClick={() => setFieldValue(`answers[${index}].score`, 0)}
                                            >
                                                Wrong answer
                                            </Button>
                                            <Button
                                                bgColor="#10B981"
                                                color="white"
                                                type="button"
                                                onClick={() =>
                                                    setFieldValue(`answers[${index}].score`, question?.maxMarks)
                                                }
                                            >
                                                Right answer
                                            </Button>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>

                        <div className="flex mt-10 justify-between ">
                            <button
                                className="appearance-none focus:outline-none"
                                type="button"
                                onClick={() => resetForm()}
                            >
                                <Text color="#6366F1">Clear Gradings</Text>
                            </button>
                            <LoadingButton type="submit">Done</LoadingButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ReadyToGradeList;
