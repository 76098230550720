import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    campuses: [],
};

export const campusMagtSlice = createSlice({
    name: 'campusMagt',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_CAMPUSES: (state, action) => {
            state.campuses = action.payload;
        },
    },
});

export const { LOADING, STOP_LOADING, GET_CAMPUSES } = campusMagtSlice.actions;

export default campusMagtSlice.reducer;
