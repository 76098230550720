import React from 'react';

import { CNav, CNavItem, CNavLink, CTabContent, CTabPane, CTabs } from '@coreui/react';
import { useLocation } from 'react-router-dom';

import { GoBack } from '../../../components/go-back';

import Questions from './tabs/Questions';
import Submissions from './tabs/Students';

const PlatformAssignmentDetails = () => {
    const { state } = useLocation();

    const { assignment } = state || {};

    return (
        <>
            <div className="mb-10">
                <GoBack title={`Assignments / ${assignment?.courseCode}`} subTitle={`${assignment?.title}`} />
            </div>

            <CTabs>
                <CNav variant="tabs">
                    <CNavItem>
                        <CNavLink>Students</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink>Questions</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane>
                        <Submissions />
                    </CTabPane>
                    <CTabPane>
                        <Questions courseTitle={assignment?.courseTitle} />
                    </CTabPane>
                </CTabContent>
            </CTabs>
        </>
    );
};

export default PlatformAssignmentDetails;
