import React, { useEffect, useState } from 'react';

import { CDataTable, CPagination } from '@coreui/react';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus.svg';
import { Button } from '../../../../components/buttons';
import Checkbox from '../../../../components/inputs/checkbox';
import SearchInput from '../../../../components/inputs/search-input';
import ItemsPerPage from '../../../../components/items-per-page';
import ListEmptyContent from '../../../../components/list-empty';
import {
    CenteredContainer,
    FlexCentredRow,
    FlexRowSpaceBetween,
    ItemsPerPageCont,
} from '../../../../containers/ScreenContainers';
import { getCourseAssignments } from '../../../../redux/assignment/actions';

const fields = [
    {
        label: <Checkbox />,
        key: 'action',
    },
    {
        label: 'Assignment Title',
        key: 'title',
    },
    {
        label: 'Total Marks',
        key: 'totalMarks',
    },
    {
        label: 'Assignment Type',
        key: 'assignmentType',
    },
    {
        label: 'Registered Students',
        key: 'numberOfStudents',
    },
    {
        label: 'Continous Assessment',
        key: 'continuousAssessment',
    },
    {
        label: 'Created At',
        key: 'createdAt',
    },
    {
        label: 'Publish Status',
        key: 'publishStatus',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Assignments = ({ toggleRefetch, selectedItem, setSelectedItem }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state } = useLocation();

    const { session, course } = state || {};
    const { isLoading } = useSelector((state) => state.assignment);

    const [assignments, setAssignments] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(assignments?.length / itemsPerPage);

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const handleCreate = () => navigate(`/assignments/create/${courseId}`, { state: { session, course } });

    const handleNavigate = (item) => {
        const state = { course, session, assignment: item };
        if (item.assignmentType === 'Platform Based') {
            navigate(`/assignments/details/${item._id}`, { state });
        } else {
            navigate(`/assignments/spreadsheet/${item._id}`, { state });
        }
    };

    useEffect(() => {
        const getAssignments = async () => {
            const res = await dispatch(getCourseAssignments(session, courseId));
            if (res) {
                setAssignments(res);
                return;
            }
            setAssignments([]);
        };

        getAssignments();
    }, [dispatch, courseId, toggleRefetch, session]);

    return (
        <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
            <FilterContainer>
                <FlexCentredRow>
                    <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                </FlexCentredRow>
            </FilterContainer>

            <CDataTable
                items={assignments || []}
                fields={fields}
                // striped
                selectable
                responsive
                itemsPerPage={itemsPerPage}
                activePage={currentPage || 1}
                clickableRows
                loading={isLoading}
                onRowClick={(item) => handleSelect(item)}
                scopedSlots={{
                    action: (item) => (
                        <td>
                            <Checkbox checked={item?._id === selectedItem?._id} />
                        </td>
                    ),
                    title: (item) => (
                        <td style={{ color: '#2563EB' }} onClick={() => handleNavigate(item)}>
                            {item.title}
                        </td>
                    ),
                    publishStatus: (item) => (
                        <td>
                            <span
                                className={`${
                                    item.publishStatus === 'Published' ? 'text-[#059669]' : 'text-[#DC2626]'
                                }`}
                            >
                                {item.publishStatus}
                            </span>
                        </td>
                    ),
                    continuousAssessment: (item) => (
                        <td>
                            <span
                                className={`${
                                    item.continuousAssessment === 'Yes' ? 'text-[#059669]' : 'text-[#DC2626]'
                                }`}
                            >
                                {item.continuousAssessment}
                            </span>
                        </td>
                    ),
                    createdAt: (item) => <td>{format(new Date(item.createdAt), 'MMMM dd, yyyy')}</td>,
                }}
                noItemsViewSlot={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Assignments yet" subtitle="Create a new assignment">
                            <Button onClick={handleCreate} bgColor="#10B981" color="#fff">
                                <Circularplus className="mr-[1.15rem]" />
                                Create New
                            </Button>
                        </ListEmptyContent>
                    </CenteredContainer>
                }
            />
            <ItemsPerPageCont>
                <ItemsPerPage options={[10, 20, 30, 40]} setItemsPerPage={setItemsPerPage} />

                <CPagination
                    activePage={currentPage || 1}
                    onActivePageChange={(nextPage) => setCurrentPage(nextPage)}
                    pages={totalPages || 1}
                    align="end"
                />
            </ItemsPerPageCont>
        </div>
    );
};

export default Assignments;
