import React, { useEffect, useState } from 'react';

import { CDataTable } from '@coreui/react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

import SearchInput from '../../../../components/inputs/search-input';
import { FlexCentredRow, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { getAssignmentDraft } from '../../../../redux/assignment/actions';

const fields = [
    // {
    //     label: <Checkbox />,
    //     key: 'action',
    // },
    {
        label: 'Title',
        key: 'title',
    },

    {
        label: 'Created At',
        key: 'createdAt',
    },
];

const FilterContainer = tw(FlexRowSpaceBetween)`
  p-[16px]
`;

const Drafts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: courseId } = useParams();
    const { state } = useLocation();

    const { session, course } = state || {};

    const [searchValue, setSearchValue] = useState('');

    const handleCreate = () => navigate(`/assignments/create/${courseId}`, { state: { session, course } });

    const [draft, setDraft] = useState(null);
    const [isGettingDraft, setIsGettingDraft] = useState(true);

    useEffect(() => {
        const getDraft = async () => {
            const data = await dispatch(getAssignmentDraft(course._id));
            if (data) {
                setDraft({ ...data.data, createdAt: data.createdAt });
            }
            setIsGettingDraft(false);
        };

        getDraft();
    }, [course._id, dispatch]);

    return (
        <div className="mt-[2.4rem] shadow-lg rounded-t-lg bg-[white] pb-[1.8rem]">
            <FilterContainer>
                <FlexCentredRow>
                    <SearchInput onChange={(e) => setSearchValue(e.target.value)} value={searchValue} withRadius />
                </FlexCentredRow>
            </FilterContainer>

            <CDataTable
                items={draft ? [draft] : []}
                fields={fields}
                // striped
                selectable
                responsive
                itemsPerPage={10}
                activePage={1}
                clickableRows
                loading={isGettingDraft}
                onRowClick={() => handleCreate()}
                scopedSlots={{
                    // action: (item) => (
                    //     <td>
                    //         <Checkbox checked={item?._id === selectedItem?._id} />
                    //     </td>
                    // ),
                    title: (item) => (
                        <td style={{ color: '#2563EB' }} onClick={() => handleCreate()}>
                            {item.title}
                        </td>
                    ),

                    createdAt: (item) => <td>{format(new Date(item.createdAt), 'MMMM dd, yyyy')}</td>,
                }}
            />
        </div>
    );
};

export default Drafts;
