export const ASSIGNMENT_TYPES = [
    { name: 'Platform Based (Composed, solved and graded on the platform)', value: 'Platform Based' },
    { name: 'Score only (Assignments graded outside the platform)', value: 'Score Only' },
];

export const QUESTION_TYPES = [
    { name: 'Objective (Multiple choice, German)', value: 'Objective' },
    { name: 'Subjective (Short answer, Long answer, File upload)', value: 'Subjective' },
    { name: 'Mixed', value: 'Mixed' },
];

export const QUESTION_TYPE_VALUES = {
    SingleAnswer: 'Multiple Choice',
    MultipleAnswer: 'Checkbox',
    German: 'German',
    ShortAnswer: 'Short Answer',
    LongAnswer: 'Long Answer',
    Upload: 'Upload',
};

export const OBJECTIVE = [
    { value: QUESTION_TYPE_VALUES.SingleAnswer, name: 'Multiple Choice - Single Answer' },
    { value: QUESTION_TYPE_VALUES.MultipleAnswer, name: 'Multiple Choice - Multiple Answers' },
    { value: QUESTION_TYPE_VALUES.German, name: QUESTION_TYPE_VALUES.German },
];

export const SUBJECTIVE = [
    { value: QUESTION_TYPE_VALUES.ShortAnswer, name: QUESTION_TYPE_VALUES.ShortAnswer },
    { value: QUESTION_TYPE_VALUES.LongAnswer, name: QUESTION_TYPE_VALUES.LongAnswer },
    { value: QUESTION_TYPE_VALUES.Upload, name: QUESTION_TYPE_VALUES.Upload },
];

export const QUESTION_TYPE_LOOKUP = {
    Objective: OBJECTIVE,
    Subjective: SUBJECTIVE,
    Mixed: [...OBJECTIVE, ...SUBJECTIVE],
};
