import React, { useState } from 'react';

import { styled } from 'twin.macro';

import { ReactComponent as PlusIcon } from '../../../assets/icons/circular-plus-grey.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { SecondaryButton } from '../../buttons';
import FilterPopup from '../filter-popup';

const Container = styled.div`
    position: relative;
    width: fit-content;
`;

const StyledButton = styled(SecondaryButton)`
    border: 1px solid #e5e7eb;
    padding: 8px 16px;
    color: #1f2937;
    gap: 8px;

    &:hover {
        cursor: pointer;
    }
`;

const AllFilter = ({
    handleReset,
    open,
    items,
    close,
    showClear,
    useSecondaryBtn,
    absolutePosition,
    ...otherProps
}) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <Container>
            {useSecondaryBtn ? (
                <PlusIcon onClick={() => setOpenModal(true)} className="ml-[14px] hover:cursor-pointer" />
            ) : (
                <>
                    {showClear ? (
                        <StyledButton onClick={() => handleReset()}>
                            Clear filters
                            <CloseIcon className="text-[#9CA3AF]" />
                        </StyledButton>
                    ) : (
                        <StyledButton onClick={() => setOpenModal(true)}>
                            <FilterIcon />
                            Filter
                        </StyledButton>
                    )}
                </>
            )}

            <FilterPopup
                absolutePosition={absolutePosition}
                open={openModal}
                close={() => setOpenModal(false)}
                items={items}
                {...otherProps}
            />
        </Container>
    );
};

export default AllFilter;
