import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';

// import { ReactComponent as DownloadIcon } from '../../../assets/icons/cloud.svg';
// import { ReactComponent as Dropdown } from '../../../assets/icons/dropdown.svg';
// import { ReactComponent as EditIcon } from '../../../assets/icons/edit-black.svg';
// import { ReactComponent as SpreadIcon } from '../../../assets/icons/spread.svg';
// import { ReactComponent as TimerIcon } from '../../../assets/icons/timer.svg';
// import { ActionsPopup } from '../../../components';
// import { Button } from '../../../components/buttons';
import EditScoreDistribution from '../../../components/forms/edit-score-distribution';
import SetScoreDistribution from '../../../components/forms/set-score-distribution';
import { GoBack } from '../../../components/go-back';
import SubmitDialogue from '../../../components/popups/submit-dialogue';
import { PageTitle } from '../../../containers/MesssageContainers';
import { FlexRowSpaceBetween } from '../../../containers/ScreenContainers';

import RegisteredStudents from './tabs/RegisteredStudents';
// import Results from './tabs/Results';

const AllotedCoursesDetails = () => {
    const {
        state: { course },
    } = useLocation();
    const { _id: courseId, courseCode, courseTitle } = course || {};

    const [openModal, setOpenModal] = useState(null);

    return (
        <div>
            <FlexRowSpaceBetween className="mb-[2rem]">
                <GoBack title="Allotted Courses" subTitle={`${courseTitle} - ${courseCode}`} />
                {/* {tab === 1 && (
                    <RelativeContainer>
                        <FlexCentredRow>
                            <Button color="#000" right="1.2rem" onClick={() => setOpenModal('submit')}>
                                <TimerIcon className="mr-[13.15px]" />
                                Submit Results
                            </Button>

                            <Button bgColor="#6366F1" color="#fff" onClick={() => setDropdownOpen(!dropdownOpen)}>
                                Actions
                                <Dropdown className="ml-[13.15px]" />
                            </Button>
                        </FlexCentredRow>

                        <ActionsPopup
                            open={dropdownOpen}
                            close={() => setDropdownOpen(false)}
                            items={[
                                {
                                    icon: <SpreadIcon />,
                                    name: 'Set Score Distribution',
                                    click: () => {
                                        setOpenModal('set-score');
                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <EditIcon />,
                                    name: 'Edit Score Distribution',
                                    click: () => {
                                        setOpenModal('edit-score');
                                        setDropdownOpen(false);
                                    },
                                },
                                {
                                    icon: <DownloadIcon />,
                                    name: 'Download Sheet',
                                    click: () => {
                                        setOpenModal('download');
                                        setDropdownOpen(false);
                                    },
                                },
                            ]}
                        />
                    </RelativeContainer>
                )} */}
            </FlexRowSpaceBetween>

            <PageTitle bottom="3.2rem" align="start">
                {courseTitle}-{courseCode}
            </PageTitle>
            <RegisteredStudents />

            <SetScoreDistribution
                show={openModal === 'set-score'}
                close={() => setOpenModal(null)}
                courseId={courseId}
            />
            <EditScoreDistribution
                show={openModal === 'edit-score'}
                close={() => setOpenModal(null)}
                courseId={courseId}
            />

            <SubmitDialogue show={openModal === 'submit'} close={() => setOpenModal(null)} />
        </div>
    );
};

export default AllotedCoursesDetails;
