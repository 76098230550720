import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ListEmptyContent from '../../components/list-empty';
import { CenteredContainer } from '../../containers/ScreenContainers';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getAllotedCourses, getAllottedSessions } from '../../redux/alloted-courses/actions';

const fields = [
    {
        header: 'Course Title',
        accessorKey: 'courseTitle',
        cell: (props) => <p className="text-[#3B82F6] cursor-pointer">{props.getValue()}</p>,
    },
    {
        header: 'Course Code',
        accessorKey: 'courseCode',
    },
    {
        header: 'Units',
        accessorKey: 'courseUnit',
    },
    {
        header: 'Registered Students',
        accessorKey: 'students',
    },
    {
        header: '% of Overall Score',
        accessorKey: 'score',
    },
];

const SEMESTER_LOOKUP = {
    'First Semester': 'first',
    'Second Semester': 'second',
};

const AllotedCoursesList = ({ pageTitle = 'Allotted Courses' }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isLoading, allottedSessions = [] } = useSelector((state) => state.allotedCourses);
    const { activeSemester, activeSession } = useSelector((store) => store.admission);

    const [courses, setCourses] = useState([]);
    const [session, setSession] = useState(activeSession || '');
    const [semester, setSemester] = useState(activeSemester || 'First Semester');
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const actionItemSessions = allottedSessions.map((item) => ({
        name: item.session,
        click: () => {
            setSession(item.session);
            setOpenDropdown(null);
        },
    }));

    const actionItemSemester = ['First Semester', 'Second Semester'].map((item) => ({
        name: item,
        click: () => {
            setSemester(item);
            setOpenDropdown(null);
        },
    }));

    useEffect(() => {
        if (!session || !semester) return;
        const semesterParam = SEMESTER_LOOKUP[semester];

        const getCourses = async () => {
            const data = await dispatch(getAllotedCourses(session, semesterParam));
            if (data) {
                setCourses(data);
            }
        };

        getCourses();
    }, [dispatch, session, semester]);

    useEffect(() => {
        dispatch(getAllottedSessions());
    }, [dispatch]);

    return (
        <div>
            <PageLayout
                pageTitle={pageTitle}
                searchable={false}
                fields={fields}
                sessionItems={actionItemSessions}
                sem
                data={courses || []}
                showTableUtils
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Course(s) yet"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                onRowClick={(item) => navigate(`${item._id}`, { state: { course: item, session } })}
                sessionValue={session}
                semesterItems={actionItemSemester}
                semesterValue={semester}
                onSemesterClick={() => handleOpenDropdown('semester')}
                openSemester={openDropdown === 'semester'}
                closeSemester={() => setOpenDropdown(null)}
                onSessionFilterClick={() => handleOpenDropdown('sessions')}
                openSessionFilter={openDropdown === 'sessions'}
                closeSessionFilter={() => setOpenDropdown(null)}
            />
        </div>
    );
};

export default AllotedCoursesList;
