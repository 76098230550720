import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { AllFilter } from '../../components/filter-select';
import FilterPopup from '../../components/filter-select/filter-popup';
import ListEmptyContent from '../../components/list-empty';
import { CenteredContainer, RelativeContainer } from '../../containers/ScreenContainers';
import { LEVELS } from '../../data/constants';
import useDebounce from '../../hooks/useDebounce';
import PageLayout from '../../layout/page-layout/PageLayout';
import { getStudents } from '../../redux/students/actions';
import { capitalizeFirstLetter } from '../../utils';

const fields = [
    {
        header: 'Full Name',
        accessorKey: 'name',
    },
    {
        header: 'Matric Number',
        accessorKey: 'matricNumber',
    },
    {
        header: 'Level',
        accessorKey: 'level',
    },
];

const FilterTag = tw.div`
    flex
    px-[8px]
    py-[4px]
    h-[25px]
    min-w-max
    bg-[#EEF2FF]
    text-[#4F46E5]
    text-[12px]
    font-semibold
`;

const StyledTag = styled(FilterTag)`
    border-top-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-bottom-left-radius: ${({ withLeftRadius }) => (withLeftRadius ? '4px' : '')};
    border-top-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
    border-bottom-right-radius: ${({ withRightRadius }) => (withRightRadius ? '4px' : '')};
`;

const Students = () => {
    const dispatch = useDispatch();

    const { students, isLoading } = useSelector((state) => state.students);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    const [filterCategories, setFilterCategories] = useState([]);
    const [currentFilterCategory, setCurrentFilterCategory] = useState('');
    const [studentLevelFilter, setStudentLevelFilter] = useState('');
    const [openModal, setOpenModal] = useState(null);

    const activeStudents = useMemo(
        () =>
            students
                ?.filter((item) => item.academicStatus === 'Active')
                ?.map((item) => ({
                    ...item,
                    name: `${capitalizeFirstLetter(item?.lastName)} ${capitalizeFirstLetter(
                        item?.firstName,
                    )} ${capitalizeFirstLetter(item?.middleName)}`,
                })),
        [students],
    );

    const generateFilterOptions = (data = [], setStateAction, closeAction, objaccessorKey) => {
        const filterOptions = data?.map((option) => {
            let item;

            if (typeof option === 'object') {
                item = option[objaccessorKey];
            } else {
                item = option;
            }

            return {
                name: item,
                click: () => {
                    setStateAction(item);
                    closeAction(null);
                },
            };
        });

        return filterOptions;
    };

    const handleSelectCategories = (category) => {
        const isSelected = filterCategories.includes(category);
        if (isSelected) return;
        setFilterCategories((prevState) => [...prevState, category]);
    };

    const handleReset = () => {
        setFilterCategories([]);
        setCurrentFilterCategory('');
        setSearchValue('');
        setStudentLevelFilter('');
    };

    const handleRemoveFilterCategory = (category, stateAction) => {
        const newItems = filterCategories.filter((item) => item !== category);
        setFilterCategories(newItems);
        if (newItems.length === 0) setCurrentFilterCategory('');
        stateAction('');
    };

    const FILTER_OPTIONS = ['Level'];

    const FILTER_POPUP_OPTIONS = {
        Level: generateFilterOptions(LEVELS, setStudentLevelFilter, setOpenModal),
    };

    const FILTER_VALUES_LOOKUP = {
        Level: {
            value: studentLevelFilter,
            stateAction: setStudentLevelFilter,
        },
    };

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.studentSearch = debouncedSearchValue;
        }
        if (studentLevelFilter) {
            queryParams.level = studentLevelFilter;
        }

        return queryParams;
    }, [debouncedSearchValue, studentLevelFilter]);

    useEffect(() => {
        dispatch(getStudents(query));
    }, [dispatch, query]);

    return (
        <div>
            <PageLayout
                pageTitle="Students"
                searchable
                fields={fields}
                data={activeStudents || []}
                showTableUtils
                showFilter
                filterItems={FILTER_OPTIONS.map((item) => ({
                    name: item,
                    click: () => {
                        handleSelectCategories(item);
                        setCurrentFilterCategory(item);
                        setOpenModal('options-popup-main');
                    },
                }))}
                showClear={currentFilterCategory}
                handleFilterReset={handleReset}
                openFilterPop={openModal === 'options-popup-main'}
                closeFilterPop={() => setOpenModal(null)}
                filterPopItems={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
                onSearchClose={() => setSearchValue('')}
                noItemView={
                    <CenteredContainer className="mt-[5rem]">
                        <ListEmptyContent isInTable title="No Records"></ListEmptyContent>
                    </CenteredContainer>
                }
                loading={isLoading}
                isFilterPopLoading={isLoading}
                filterCategories={
                    <div>
                        {filterCategories?.length > 0 && (
                            <div className="flex flex-wrap items-center gap-4 p-[18px] border-t">
                                {filterCategories.map((category) => (
                                    <div key={category} className="flex gap-[1px]">
                                        <StyledTag withLeftRadius>{category}</StyledTag>

                                        {FILTER_VALUES_LOOKUP[category].value && (
                                            <StyledTag withRightRadius>
                                                {FILTER_VALUES_LOOKUP[category].value}
                                                <CloseIcon
                                                    className="ml-[4px] w-[10px] hover:cursor-pointer text-[#6366F1]"
                                                    onClick={() =>
                                                        handleRemoveFilterCategory(
                                                            category,
                                                            FILTER_VALUES_LOOKUP[category].stateAction,
                                                        )
                                                    }
                                                />
                                            </StyledTag>
                                        )}
                                    </div>
                                ))}

                                <RelativeContainer>
                                    <AllFilter
                                        useSecondaryBtn
                                        showClear={currentFilterCategory}
                                        items={FILTER_OPTIONS.map((item) => ({
                                            name: item,
                                            click: () => {
                                                handleSelectCategories(item);
                                                setCurrentFilterCategory(item);
                                                setOpenModal('options-popup-sub');
                                            },
                                        }))}
                                        handleReset={handleReset}
                                    />
                                    <FilterPopup
                                        open={openModal === 'options-popup-sub'}
                                        isLoading={isLoading}
                                        close={() => setOpenModal(null)}
                                        items={FILTER_POPUP_OPTIONS[currentFilterCategory]}
                                    />
                                </RelativeContainer>
                            </div>
                        )}
                    </div>
                }
            />
        </div>
    );
};
export default Students;
